<template>
  <div>
    <AppContextualSaveBar />
    <PageHeading
      subtitle="Use local delivery areas settings to give your customers local delivery option at checkout."
    >
      <template #title>
        <span class="opacity-60">Local delivery for</span>
        <span class="ml-2">{{ title }}</span>
      </template>
    </PageHeading>

    <LocalDeliveryDetails class="mt-4" />
    <LocalDeliveryArea class="mt-4" />

    <div class="flex gap-2 mt-4">
      <v-spacer />
      <v-btn
        @click="save"
        :loading="saving"
        :disabled="!canSave"
        color="primary"
        elevation="1"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Location } from "~/layers/admin/models";
import LocalDeliveryDetails from "~/components/pages/settings/shipping/LocalDeliveryDetails.vue";
import LocalDeliveryArea from "~/components/pages/settings/shipping/LocalDeliveryArea.vue";

export default defineComponent({
  components: {
    LocalDeliveryDetails,
    LocalDeliveryArea,
  },
  setup() {
    definePageMeta({
      layout: "settings",
    });

    const route = useRoute();
    const handle = String(route.params.handle);
    const { original, canSave, saving, save } = useSaveContext<any>({
      query: () => {
        const query = Location.query()
          .select([
            "handle",
            "name",
            "address_line",
            "local_delivery",
            "verified",
            "local_delivery_type",
            "local_delivery_radius",
          ])
          .include("zones", {
            select: ["*"],
            include: {
              pricing: {
                select: ["*"],
                include: ["tiers"],
              },
            },
          });

        return query.findUnique({ handle });
      },
    });

    const title = computed(() => original.value.name || handle);

    return {
      title,
      canSave,
      saving,
      save,
    };
  },
});
</script>

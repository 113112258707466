<template>
  <div class="text-h6">Password Reset</div>
  <template v-if="sent">
    <p class="mt-5">Please check your inbox to change your password.</p>
    <div class="subheader my-8">
      A password reset link has been sent to
      <b>{{ credentials.email }}</b>
    </div>
  </template>
  <template v-else>
    <p class="text-body mt-5 mb-4">Reset your seller account password</p>
    <v-dynamic-form
      v-model="credentials"
      v-model:valid="valid"
      :inputs="inputs"
      :readonly="loading"
    />
    <v-btn
      @click="send"
      class="mt-4"
      color="primary"
      :loading="loading"
      :disabled="!valid"
      size="large"
      block
      >Submit</v-btn
    >
  </template>
  <div class="mt-2 text-left">
    <v-btn :to="{ name: 'login' }" variant="text" class="ml--2">
      <span>Back to login</span>
      <v-icon size="20">chevron_right</v-icon>
    </v-btn>
  </div>
  <v-alert
    v-if="error"
    class="my-2"
    border="end"
    type="error"
    colored-border
    density="compact"
    variant="tonal"
  >
    Invalid email
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
import {
  AuthResponseStatus,
  type ForgotPasswordInput,
} from "~/layers/admin/modules/auth/runtime/types/api";
import { Rule } from "~/services/Rules";

export default defineComponent({
  setup() {
    definePageMeta({
      layout: "auth",
      auth: false,
    });

    // page title: "Forgot Password"

    const { links } = useAppConfig();
    const auth = useAuth();
    const toast = useToast();

    const credentials = ref<ForgotPasswordInput>({ email: "" });
    const loading = ref(false);
    const error = ref(false);
    const sent = ref(false);

    const send = () => {
      loading.value = true;
      error.value = false;

      if (credentials.value) {
        const input = toRaw(credentials.value);
        auth
          .forgotPassword(input)
          .then((response) => {
            if (response?.status !== AuthResponseStatus.EMAIL_SENT) {
              error.value = true;
            } else {
              sent.value = true;
            }
          })
          .catch(() => {
            toast.error("Failed to send password reset request");
            error.value = true;
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    return {
      links,
      credentials,
      sent,
      loading,
      error,
      send,
    };
  },
  data: () => ({
    valid: false,
    inputs: defineFormInputs({
      email: {
        hideName: true,
        type: "text",
        rules: Rule.required(),
        props: {
          label: "Email",
        },
      },
    }),
  }),
});
</script>

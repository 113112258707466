<template>
  <v-container>
    <PageHeading title="Draft orders" />
    <div>Orders</div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    definePageMeta({
      rememberNav: "Draft orders",
    });
  },
});
</script>

<template>
  <OverlayLoader class="h-full" :loading="fetching" loader-size="100">
    <EventSeating />
  </OverlayLoader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EventSeating, { events } from "~/components/pages/event-seating";
import { useSeatingStore } from "~/components/pages/event-seating";

export default defineComponent({
  components: { EventSeating },
  setup() {
    definePageMeta({
      layout: "canvas",
    });

    const route = useRoute();
    const router = useRouter();

    const store = useSeatingStore();
    const { fetching } = storeToRefs(store);

    const handle = String(route.params.handle);
    const occurrence = String(route.params.occurrence);

    events.hook("exit", () => {
      if (history.length) {
        router.back();
      } else {
        router.push({
          name: "events-handle",
          params: { handle },
        });
      }
    });

    onMounted(() => {
      store.load(handle, occurrence);
    });

    return {
      fetching,
    };
  },
});
</script>

<template>
  <div>
    <AppContextualSaveBar />
    <PageHeading
      class="mb-4"
      title="Invite staff member"
      subtitle="Give staff access to your workspace by sending them an invitation."
    />
    <v-card class="overflow-hidden pa-4">
      <v-dynamic-form
        v-model="data"
        v-model:valid="valid"
        :disabled="saving"
        :inputs="inputs"
      >
        <template #divider1:field><v-divider class="my-4" /></template>
        <template #divider2:field><v-divider class="my-4" /></template>
      </v-dynamic-form>
    </v-card>

    <div class="mt-4 flex">
      <v-spacer />
      <v-btn
        @click="save"
        :disabled="!canSave"
        :loading="saving"
        color="primary"
        elevation="1"
        >Send invite
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import gql from "graphql-tag";
import { Rule } from "~/services/Rules";
import { PermissionsInput, RolesInput } from "#components";
import { useToast } from "vue-toastification";

export default defineComponent({
  data: () => ({
    inputs: defineFormInputs({
      name: {
        name: "Name",
        line: 1,
        rules: Rule.group([Rule.required(), Rule.string.max(32)]),
        type: "text",
        props: {
          //
        },
      },
      email: {
        line: 1,
        name: "Email",
        rules: Rule.group([Rule.required(), Rule.email()]),
        type: "text",
      },
      divider1: {
        hideName: true,
      },
      roles: {
        name: "Roles",
        component: RolesInput,
        props: {
          owner: "staff",
        },
      },
      divider2: {
        hideName: true,
      },
      permissions: {
        name: "Permissions",
        component: PermissionsInput,
        // rules: Rule.required(),
        props: {
          owner: "staff",
        },
      },
    }),
  }),
  setup() {
    definePageMeta({
      layout: "settings",
    });

    const { wrapLoading } = useLoading();
    const apollo = useApollo();
    const router = useRouter();
    const toast = useToast();

    const { canSave, save, saving, canEdit, data, useValid } =
      useSaveContext<any>({
        saveText: "Send",
        save: wrapLoading(({ data }) => {
          return apollo
            .mutate({
              mutation: gql`
                mutation ($data: WsCreateInvitationInput!) {
                  wsCreateInvitation(data: $data) {
                    id
                  }
                }
              `,
              variables: { data },
            })
            .then(() => {
              toast.success(`Invite sent to ${data.name}`);
              setTimeout(() => {
                router.push({
                  name: "settings-team",
                  hash: "#members",
                });
              }, 1000);
            });
        }, "Sending invitation"),
      });

    const valid = useValid();

    return {
      valid,
      saving,
      canEdit,
      canSave,
      data,
      save,
    };
  },
});
</script>

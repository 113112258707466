<template>
  <div>
    <PageHeading
      title="Business profile"
      subtitle="This is the default public profile of your business on Orie."
      class="mb-4"
      :actions="actions"
      actions-namespace="business-profile"
      no-return
    />
    <ProfileDetails />
    <BackgroundDetails class="mt-4" />
    <ContactDetails class="mt-4" />
    <SupportDetails class="mt-4" />
    <SocialMediaHandles class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProfileDetails from "~/components/pages/settings/profiles/ProfileDetails.vue";
import BackgroundDetails from "~/components/pages/settings/profiles/BackgroundDetails.vue";
import ContactDetails from "~/components/pages/settings/profiles/ContactDetails.vue";
import SupportDetails from "~/components/pages/settings/profiles/SupportDetails.vue";
import SocialMediaHandles from "~/components/pages/settings/profiles/SocialMediaHandles.vue";

export default defineComponent({
  components: {
    ProfileDetails,
    BackgroundDetails,
    ContactDetails,
    SupportDetails,
    SocialMediaHandles,
  },
  setup() {
    definePageMeta({
      layout: "settings",
    });

    const transaction = useTransaction<any>("business-profile");
    const { handle } = useNamespacedEvent("business-profile");

    provide("transaction", transaction);
    handle("save", transaction.save);

    const actions = computed(() => {
      return defineActions([
        {
          title: "Save",
          key: "save",
          primary: true,
          loading: transaction.saving.value,
          disabled: !transaction.isDirty.value,
        },
      ]);
    });

    return {
      actions,
    };
  },
});
</script>

<template>
  <v-container>
    <PageHeading
      title="Giftcards"
      :actions="actions"
      actions-namespace="giftcards"
      broadcast-actions
      no-return
    />
    <DataTable class="mt-10" v-bind="table" :loading="loading" :items="items" />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    definePageMeta({
      rememberNav: "Giftcards",
    });

    const loading = ref(false);
    const items: any[] = [];

    return {
      loading,
      items,
    };
  },
  data: () => ({
    actions: defineActions([
      {
        title: "Issue gift card",
        key: "add:giftcard",
        disabled: true,
        primary: true,
      },
    ]),

    table: defineTable({
      searchable: true,
      applyFilters: true,
      actionsNamespace: "giftcards",

      // itemRoute: (item: Giftcard) => ({
      //   name: "products-giftcards-handle",
      //   params: { handle: item.handle },
      // }),

      emptyIcon: "heroicons:gift",
      emptyTitle: "No gift cards have been sold or sent yet",
      emptyDescription:
        "When a customer buys a gift card from your store, or when you, issue one to them, it will show up here.",

      columns: [
        // { title: "ID", sortable: false, key: "id" },
        // { title: "Name", sortable: true, key: "name", type: "title" },
      ],

      itemActions: [
        {
          title: "Delete",
          key: "giftcard:delete",
          color: "red",
          icon: "mdi:delete-sweep-outline",
        },
      ],
    }),
  }),
});
</script>

<template>
  <div class="text-center mb-4">
    <div class="text-h6">Welcome</div>
    <p class="text-body mt-5">Login to Seller Dashboard</p>
  </div>
  <v-dynamic-form
    v-model="credentials"
    v-model:valid="valid"
    :inputs="inputs"
    @keyup.enter="submit"
  />
  <v-btn
    @click="login"
    class="mt-4"
    color="primary"
    :loading="loading"
    :disabled="!valid"
    size="large"
    block
    >Login</v-btn
  >
  <div class="mt-2 text-left">
    <v-btn :to="{ name: 'oauth-forgot-password' }" variant="text" class="ml--2">
      <span>Forgot password</span>
      <v-icon size="20">chevron_right</v-icon>
    </v-btn>
  </div>
  <v-alert
    v-if="error"
    class="my-2"
    border="end"
    type="error"
    colored-border
    density="compact"
    variant="tonal"
  >
    Invalid login credentials
  </v-alert>
  <v-divider class="my-5">Or</v-divider>
  <v-card :to="{ name: 'register' }" rounded="lg" border flat>
    <v-card-actions>
      <div>
        <div class="text-3.4">Create a free account</div>
        <div class="text-3">Sign up for a free Seller account.</div>
      </div>
      <v-spacer />
      <v-btn icon>
        <v-icon>arrow_right_alt</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PasswordInputField } from "#components";
import { Rule } from "~/services/Rules";

export default defineComponent({
  setup() {
    definePageMeta({
      layout: "auth",
      auth: false,
      middleware: "redirect-login",
    });

    const loading = ref(false);
    const error = ref(false);
    const valid = ref(false);
    const { links } = useAppConfig();

    const credentials = ref({
      username: "",
      password: "",
    });

    const auth = useAuth();

    const inputs = computed(() => {
      return defineFormInputs({
        username: {
          name: "Email",
          type: "text",
          rules: Rule.required(),
          props: {
            label: "Email",
          },
        },
        password: {
          name: "Password",
          rules: Rule.required(),
          component: PasswordInputField,
          props: {
            label: "Password",
          },
        },
      });
    });

    const login = () => {
      loading.value = true;
      auth
        .login(credentials.value)
        .then((result) => {
          error.value = false;

          // Redirect to onboarding right after login if
          // there is no workspace. We need this before any other
          // invalid workspace API calls.
          if (result?.context && !result.context.workspace) {
            navigateTo({ name: "onboarding-new-store" });
          }
        })
        .catch(() => {
          error.value = true;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const submit = () => {
      if (valid.value) {
        login();
      }
    };

    return {
      credentials,
      inputs,
      login,
      loading,
      error,
      valid,
      links,
      submit,
    };
  },
});
</script>

<template>
  <div>
    <PageHeading title="Create shipping profile" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import ShippingProfiles from "~/components/pages/settings/shipping/ShippingProfiles.vue";

export default defineComponent({
  // components: { ShippingProfiles, },
  setup() {
    definePageMeta({
      layout: "settings",
    });
  },
});
</script>

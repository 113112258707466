<template>
  <Sta />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Sta, { events, middleware } from "~/components/pages/sta";

export default defineComponent({
  components: { Sta },
  setup() {
    definePageMeta({
      layout: "canvas",
      auth: false,
      middleware,
    });

    const route = useRoute();
    const router = useRouter();
    const { loggedIn } = useAuth();
    const { loading } = useLoading();

    const handle = String(route.params.handle);

    const exit = () => {
      loading(true);
      // Git camera time to unmount
      setTimeout(() => {
        loading(false);
        if (loggedIn.value) {
          router.replace({
            name: "events-handle",
            params: { handle },
          });
        } else {
          window.opener = self;
          window.close();
        }
      }, 2000);
    };

    events.hook("exit", exit);
  },
});
</script>

<template>
  <div>
    <PageHeading
      title="Locations"
      subtitle="Manage the places you stock inventory, fulfill orders, and sell products."
      no-return
    />
    <Locations :loading="loading" :locations="locations" class="mt-4" />
    <DefaultLocation :loading="loading" :locations="locations" class="mt-4" />
    <FulfillmentPriority
      :loading="loading"
      :locations="locations"
      class="mt-4"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Locations from "~/components/pages/settings/locations/Locations.vue";
import DefaultLocation from "~/components/pages/settings/locations/DefaultLocation.vue";
import FulfillmentPriority from "~/components/pages/settings/locations/FulfillmentPriority.vue";
import { Location } from "~/layers/admin/models";

export default defineComponent({
  components: { Locations, DefaultLocation, FulfillmentPriority },
  setup() {
    definePageMeta({
      layout: "settings",
      rememberNav: "Locations",
    });

    const query = Location.select(
      "handle",
      "name",
      "default",
      "address_line",
      "fulfills_online_orders",
      "fulfillment_priority"
    ).policy("cache-first");

    const { items: locations, loading } = useListQuery<Location>(query);

    return {
      loading,
      locations,
    };
  },
});
</script>

<template>
  <div>
    <AppContextualSaveBar />
    <PageHeading>
      <template #title>
        <span class="opacity-60">Team member:</span>
        <span class="ml-2">{{ title }}</span>
      </template>
    </PageHeading>

    <MemberProfileDetails class="mt-4" />
    <MemberAvailability class="mt-4" />
    <MemberAppointmentBooking class="mt-4" />
    <MemberCalendar class="mt-4" />

    <div class="flex gap-2 mt-4">
      <v-spacer />
      <v-btn
        v-if="!isOwner"
        @click="del"
        :loading="deleting"
        :disabled="!canEdit"
        color="red"
        elevation="1"
        >Delete
      </v-btn>
      <v-btn
        @click="save"
        :loading="saving"
        :disabled="!canSave"
        color="primary"
        elevation="1"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MemberProfileDetails from "~/components/pages/settings/team/MemberProfileDetails.vue";
import MemberAppointmentBooking from "~/components/pages/settings/team/MemberAppointmentBooking.vue";
import MemberAvailability from "~/components/pages/settings/team/MemberAvailability.vue";
import MemberCalendar from "~/components/pages/settings/team/MemberCalendar.vue";
import TeamMember from "~/models/dobby/TeamMember";

export default defineComponent({
  components: {
    MemberProfileDetails,
    MemberAppointmentBooking,
    MemberAvailability,
    MemberCalendar,
  },
  setup() {
    definePageMeta({
      layout: "settings",
    });

    const route = useRoute();
    const { user } = useAuth();
    const id = String(route.params.id);

    const { original, canSave, canEdit, saving, save } = useSaveContext<any>({
      query: () => {
        const query = TeamMember.selectAll()
          .include("avatar")
          .include("schedules", (query) =>
            query.selectAll().include("availability")
          );
        return query.findUnique({ id });
      },
    });

    const title = computed(() => original.value.name || `#${id}`);
    const isOwner = computed(() => original.value.email == user.value?.email);

    const { del, deleting } = useSaveContextDeleteModel({
      model: TeamMember,
      routeOnDelete: { name: "settings-team", hash: "#members" },
      message: original.value.has_seller_account
        ? "Are you sure? This member's data and access to this workspace will be removed."
        : undefined,
    });

    return {
      canSave,
      canEdit,
      saving,
      deleting,
      title,
      isOwner,
      del,
      save,
    };
  },
});
</script>

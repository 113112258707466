<template>
  <div>
    <AppContextualSaveBar />
    <PageHeading title="New Location" />
    <LocationInput class="mt-4" />
    <div class="mt-4 flex">
      <v-spacer />
      <v-btn
        @click="save"
        :disabled="!canSave"
        :loading="loading"
        color="primary"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LocationInput from "~/components/pages/settings/locations/LocationInput.vue";
import { Location } from "~/layers/admin/models";

export default defineComponent({
  components: { LocationInput },
  setup() {
    definePageMeta({
      layout: "settings",
      usageAccess: "inventory-locations",
    });

    const router = useRouter();

    const { canSave, loading, save, onSaved } = useSaveContext<any>({
      save: async ({ data }) => {
        return Location.create({
          ...data,
          address: {
            create: data.address,
          },
        });
      },
      unsavedText: "Unsaved location",
      discardConfirmMessage:
        "If you discard changes, this location will not be saved.",
      exitOnDiscard: true,
      defaults: {
        fulfills_online_orders: true,
      },
    });

    onSaved((location: Location) => {
      router.push({
        name: "settings-locations-handle",
        params: { handle: location.handle },
      });
    });

    return {
      canSave,
      loading,
      save,
    };
  },
});
</script>

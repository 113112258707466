<template>
  <v-container>
    <PageHeading
      title="Orders"
      :actions="actions"
      actions-namespace="orders"
      broadcast-actions
    />

    <Exporter
      title="Export orders"
      name="store_orders"
      actions-namespace="orders"
      action="orders:export"
    />

    <ListOrders class="mt-10" :orders="items" :loading="loading" />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Order } from "~/models/dobby/Order";
import ListOrders from "~/components/pages/order/ListOrders";

export default defineComponent({
  components: { ListOrders },
  setup() {
    definePageMeta({
      rememberNav: "Orders",
    });

    const { listen } = useAppEvent();

    const { items, loading } = useListData<Order>({
      query: Order.select([
        "kind",
        "id",
        "number",
        "created_at",
        "email",
        "total",
        "payment_status",
        "fulfillment_status",
        "channel",
        // 'deliver_method',
      ]).include("items", ["id"]),
    });

    listen("orders:export", () => {
      console.log("handle orders:export");
    });

    return {
      items,
      loading,
    };
  },
  data: () => ({
    actions: defineActions([
      {
        title: "Export",
        key: "orders:export",
        icon: "carbon:export",
      },
      // {
      //   title: "Create order",
      //   key: "add:order",
      //   primary: true,
      // },
    ]),
  }),
});
</script>

<template>
  <div>
    <PageHeading title="Profile" class="mb-4" no-return />
    <ProfileDetails />
    <DeleteProfile class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProfileDetails from "~/components/pages/settings/profile/ProfileDetails.vue";
import DeleteProfile from "~/components/pages/settings/profile/DeleteProfile.vue";

export default defineComponent({
  components: { ProfileDetails, DeleteProfile },
  setup() {
    definePageMeta({
      layout: "settings",
    });
  },
});
</script>

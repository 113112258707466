<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import gql from "graphql-tag";
import { useQuery } from "@vue/apollo-composable";

export default defineComponent({
  setup() {
    definePageMeta({
      layout: "blank",
    });

    const router = useRouter();

    const { onResult } = useQuery<{ wsOnboardingLinks: { url: string } }>(
      gql`
        query ($refresh_url: String!, $return_url: String!) {
          wsOnboardingLinks(
            refresh_url: $refresh_url
            return_url: $return_url
          ) {
            url
            expires_at
          }
        }
      `,
      {
        refresh_url:
          window.location.origin + router.resolve({ name: "onboarding" }).href, // this current location
        return_url:
          window.location.origin + router.resolve({ name: "index" }).href,
      }
    );

    onResult(({ data }) => {
      navigateTo(data.wsOnboardingLinks.url, { external: true });
    });
  },
  mounted() {
    this.$loading(true, "Preparing account");
  },
});
</script>

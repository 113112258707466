export enum PeriodIntervalUnit {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum PublishableStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum PublicationStatus {
  /**
   * The item is not published and is not visible on the sales channel.
   */
  UNPUBLISHED = "UNPUBLISHED",

  /**
   * The item has been submitted for publication and is currently being processed.
   */
  PUBLISHING = "PUBLISHING",

  /**
   * The item is awaiting approval before it can be published.
   */
  PENDING = "PENDING",

  /**
   * The publication attempt failed due to an error.
   */
  FAILED = "FAILED",

  /**
   * The item is under review by an operator before approval.
   */
  IN_REVIEW = "IN_REVIEW",

  /**
   * The item is fully published and live on the sales channel.
   */
  PUBLISHED = "PUBLISHED",

  /**
   * The item was previously published but is awaiting approval for new changes.
   */
  PUBLISHED_PENDING = "PUBLISHED_PENDING",

  /**
   * The publication request was reviewed and rejected by an operator.
   */
  REJECTED = "REJECTED",

  /**
   * The item’s publication has been paused manually by an operator.
   */
  ON_HOLD = "ON_HOLD",

  /**
   * The item is scheduled to be published at a future date.
   */
  SCHEDULED = "SCHEDULED",
}

export enum PublishedStatus {
  UNPUBLISHED = "UNPUBLISHED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  IN_REVIEW = "IN_REVIEW",
  PUBLISHED = "PUBLISHED",
  REJECTED = "REJECTED",
  ON_HOLD = "ON_HOLD",
  UNPUBLISHED_CHANGES = "UNPUBLISHED_CHANGES",
  MIXED = "MIXED",
}

export enum UserAccountStatus {
  DISABLED = "DISABLED",
  INVITED = "INVITED",
  ENABLED = "ENABLED",
  DECINED = "DECINED",
}

export enum ExpectedPickupTime {
  T1H = "T1H", // 1 hour
  T2H = "T2H", // 2 hours
  T4H = "T4H", // 4 hours
  T24H = "T24H", // 24 hours
  T2D4D = "T2D4D", // 2-4 days
  T5DP = "T5DP", // 5+ days
}

export enum LocalDeliveryType {
  ZONE = "ZONE",
  RADIUS = "RADIUS",
}

export enum ServiceKind {
  STANDARD = "STANDARD",
  MEETING = "MEETING",
  PACKAGE = "PACKAGE",
}

export enum ServicePeriodType {
  UNLIMITED = "UNLIMITED",
  ROLLING = "ROLLING",
  RANGE = "RANGE",
}

export enum ServicePricingType {
  PRICING = "PRICING",
  DESCRIPTION = "DESCRIPTION",
  NONE = "NONE",
}

export enum BookingConfirmation {
  /**
   * Booking is created and automatically confirmed.
   * Any payment is also captured automatically.
   */
  AUTOMATIC = "AUTOMATIC",

  /**
   * Booking is placed but must be confirmed manually by agent.
   * Applicable payments are created but only captured when
   * booking is confirmed.
   */
  MANUAL = "MANUAL",

  /**
   * When booking is placed, payment is collected without
   * confirmation. Agent cant later confirm, reschedule, or refund booking.
   */
  OPTIMISTIC = "OPTIMISTIC",
}

export enum ServiceSchedulingType {
  ROUND_ROBIN = "ROUND_ROBIN",
  COLLECTIVE = "COLLECTIVE",
  MANAGED = "MANAGED",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  UNISEX = "UNISEX",
}

export enum TimeFormat {
  F12 = "F12",
  F24 = "F24",
}

export enum ServiceLocationType {
  LOCATION = "LOCATION",
  COLLECT_ADDRESS = "COLLECT_ADDRESS",
  ADDRESS = "ADDRESS",
  LINK = "LINK",
  COLLECT_PHONE = "COLLECT_PHONE",
  PHONE = "PHONE",
}

export enum ServiceBookingStatus {
  /**
   * Booking was cancelled by the booker.
   */
  CANCELLED = "CANCELLED",

  /**
   * Booking was accepted (either automatically or by provider)
   */
  ACCEPTED = "ACCEPTED",

  /**
   * Booking was rejected by provider.
   */
  REJECTED = "REJECTED",

  /**
   * Booking has been made, pending.
   */
  PENDING = "PENDING",
}

export enum EventAttendeeParticipationStatus {
  NEEDS_ACTION = "NEEDS_ACTION",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  TENTATIVE = "TENTATIVE",
  DELEGATED = "DELEGATED",
}

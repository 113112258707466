<template>
  <div>
    <AppContextualSaveBar />
    <PageHeading
      subtitle="Use local pickup settings to give your customers the option to pick up their online orders at your retail store or any location that you choose."
    >
      <template #title>
        <span class="opacity-60">Local pickup for</span>
        <span class="ml-2">{{ title }}</span>
      </template>
    </PageHeading>

    <LocalPickupDetails class="mt-4" />
    <LocalPickupCheckoutInfo class="mt-4" />
    <LocalPickupInstructions class="mt-4" />

    <div class="flex gap-2 mt-4">
      <v-spacer />
      <v-btn
        @click="save"
        :loading="saving"
        :disabled="!canSave"
        color="primary"
        elevation="1"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Location } from "~/layers/admin/models";
import LocalPickupDetails from "~/components/pages/settings/shipping/LocalPickupDetails.vue";
import LocalPickupCheckoutInfo from "~/components/pages/settings/shipping/LocalPickupCheckoutInfo.vue";
import LocalPickupInstructions from "~/components/pages/settings/shipping/LocalPickupInstructions.vue";

export default defineComponent({
  components: {
    LocalPickupDetails,
    LocalPickupCheckoutInfo,
    LocalPickupInstructions,
  },
  setup() {
    definePageMeta({
      layout: "settings",
    });

    const route = useRoute();
    const handle = String(route.params.handle);
    const { original, canSave, saving, save } = useSaveContext<any>({
      query: () => {
        const query = Location.query().select(
          "handle",
          "name",
          "address_line",
          "local_pickup",
          "expected_pickup_time",
          "pickup_instructions"
        );

        return query.findUnique({ handle });
      },
    });

    const title = computed(() => original.value.name || handle);

    return {
      title,
      canSave,
      saving,
      save,
    };
  },
});
</script>

<template>
  <v-container>
    <PageHeading
      title="Collections"
      :actions="actions"
      actions-namespace="collections"
      broadcast-actions
      no-return
    />
    <DataTable class="mt-10" v-bind="table" :loading="loading" :items="items" />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Collection from "~/models/dobby/Collection";

export default defineComponent({
  setup() {
    definePageMeta({
      rememberNav: "Product collections",
    });

    const { items, loading } = useListData({
      query: Collection.select(["id", "handle", "name"]).include("products", [
        "handle",
      ]),
      actions: {
        namespace: "collections",
        removeOne: "collection:delete",
      },
    });

    return {
      items,
      loading,
    };
  },
  data: () => ({
    actions: defineActions([
      {
        title: "Create collection",
        key: "add:collection",
        primary: true,
      },
    ]),

    table: defineTable({
      searchable: true,
      applyFilters: true,
      actionsNamespace: "collections",
      avatar: true,

      itemRoute: (item: Collection) => ({
        name: "products-collections-handle",
        params: { handle: item.handle },
      }),

      emptyIcon: "heroicons:queue-list",
      emptyTitle: "Manage product collections",
      emptyDescription:
        "You have no product collections created. Group your products into collections to make it easier for customers to find them by category",

      columns: [
        { title: "Name", sortable: true, key: "name", type: "title" },
        { title: "Products", sortable: false, key: "products", type: "count" },
      ],

      itemActions: [
        {
          title: "Delete",
          key: "collection:delete",
          color: "red",
          icon: "mdi:delete-sweep-outline",
        },
      ],
    }),
  }),
});
</script>

<template>
  <v-container>
    <PageHeading title="Event Calendar" no-return />
    <EventCalendar class="mt-4" v-bind="calendar" />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Event from "~/models/dobby/Event";

export default defineComponent({
  setup() {
    definePageMeta({
      rememberNav: "Event calendar",
    });

    const query = Event.calendarDisplayQuery();
    const { items, loading } = useListQuery<Event>(query);

    const calendar = computed(() => {
      return defineCalendar({
        loading: loading.value,
        events: items.value,
      });
    });

    return {
      loading,
      calendar,
    };
  },
});
</script>

<template>
  <v-container>
    <RequiresBusinessProfile class="mb-4" />

    <PageHeading title="Products" :actions="actions" broadcast-actions />

    <DataTable
      class="mt-10"
      v-bind="table"
      :loading="loading"
      :items="products"
    />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RequiresBusinessProfile from "~/components/pages/products/RequiresBusinessProfile.vue";
import { PublishableStatus } from "~/layers/admin/models";
import Product from "~/models/dobby/Product";

export default defineComponent({
  components: { RequiresBusinessProfile },
  setup() {
    definePageMeta({
      rememberNav: "Products",
    });

    const query = Product.select(["handle", "name", "status"])
      .include("image")
      .include("inventory", (q) => q.include("inventory"));

    const { items: products, loading } = useListQuery<Product>(query);

    const { listen } = useAppEvent();

    listen("products:export", () => {
      console.log("handle products:export");
    });

    return {
      loading,
      products,
    };
  },
  data: () => ({
    actions: defineActions([
      {
        title: "Export",
        key: "products:export",
      },
      {
        title: "Import",
        key: "products:import",
      },
      {
        title: "More actions",
        items: [
          {
            title: "Hide analytics bar",
            key: "hide-analytics-bar",
            icon: "jam:aperture",
          },
          {
            title: "Hide analytics bar",
            key: "2",
            icon: "jam:aperture",
          },
          {
            title: "Group",
            key: "3",
            icon: "jam:aperture",
          },
        ],
      },
      {
        title: "Add product",
        key: "add:product",
        primary: true,
      },
    ]),
    table: defineTable({
      showSelect: true,
      searchable: true,
      configurable: true,
      applyFilters: true,
      broadcastActions: true,
      // mobile: true,
      avatar: true,

      emptyDescription: "You have no products created.",

      itemRoute: (item: Product) => ({
        name: "products-handle",
        params: { handle: item.handle },
      }),

      views: [
        { id: "all", title: "All" },
        {
          id: "draft",
          title: "Draft",
          emptyDescription: "Draft products will appear here.",
          filters: tableFilter({ status: PublishableStatus.DRAFT }),
        },
        {
          id: "active",
          title: "Active",
          emptyDescription:
            "Products that are published and are active will appear here.",
          filters: tableFilter({ status: PublishableStatus.ACTIVE }),
        },
        {
          id: "archived",
          title: "Archived",
          emptyDescription: "Archived products will appear here.",
          filters: tableFilter({ status: PublishableStatus.ARCHIVED }),
        },
      ],

      columns: [
        {
          title: "Product",
          key: "name",
          sortable: true,
          width: "300px",
          type: "title",
        },
        { title: "Status", key: "status", type: "chip" },
        {
          title: "Inventory",
          key: "inventory",
          component: dataTableComponent("div"),
        },
        { title: "Channels", key: "channels", type: "count" },
      ],
      actions: [
        {
          title: "Archive products",
          key: "products:archive",
        },
        {
          title: "Delete",
          key: "products:delete",
          color: "red",
          icon: "mdi:delete-sweep-outline",
        },
      ],
    }),
  }),
});
</script>


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexi1KSW7P0ldLSNJUjejSv_WBKnEa4IlVJiYW1BXH6k70Meta } from "/opt/build/repo/pages/access/index.vue?macro=true";
import { default as pricingoT73xqs6a2HW11UcVEGskeWFr6i045A899ru6KkwKN0Meta } from "/opt/build/repo/pages/access/pricing.vue?macro=true";
import { default as indexmpuAyzkpD2Zzqmxxz0yBWn1S7tWJuiBpl3o0zUjEX88Meta } from "/opt/build/repo/pages/access/subscription/confirm/[plan]/index.vue?macro=true";
import { default as successOVMOLo79h48Y8DVIOS8XbZcRl2gA3DY2yyGek7zkAScMeta } from "/opt/build/repo/pages/access/subscription/success.vue?macro=true";
import { default as indexxAO7eYZrKEmA6NPiqwgpcwsp6vrgL9uRaaM2XxaDsMoMeta } from "/opt/build/repo/pages/access/usage/[service]/index.vue?macro=true";
import { default as indexgMaqzwBys095Od9di8Ob5mr1lcHABzgJirQza4KfAdYMeta } from "/opt/build/repo/pages/analytics/index.vue?macro=true";
import { default as saleswtmR4D6ihcppep49ErFGLbVtJofB_45KYiYV3Mwv7fz_450Meta } from "/opt/build/repo/pages/analytics/reports/sales.vue?macro=true";
import { default as _91_46_46_46handle_93wYK24JAKBc46VGOoC1fEehParN0WdRKqP5ImrIVz7FUMeta } from "/opt/build/repo/pages/apps/[...handle].vue?macro=true";
import { default as _91app_93Ytf_HUpUVfagMcZa11S4RfG2Ta_45zY1Qn7TqaOiUdWHwMeta } from "/opt/build/repo/pages/apps/manage/[app].vue?macro=true";
import { default as storeSfpl5V4nay3WRpIYsuHbme2Ziv0SfNp1_45BGN9v2jZlMMeta } from "/opt/build/repo/pages/apps/store.vue?macro=true";
import { default as _91handle_934hrL76mN99a5XNsRfirbdIx37mb9gqXU44GNO_45F6_45b4Meta } from "/opt/build/repo/pages/bio-sites/[handle].vue?macro=true";
import { default as indexakdW2MCuV8gWCfjjfxzXsLF52gq4Q8Xogh9ICvAY3P4Meta } from "/opt/build/repo/pages/bio-sites/index.vue?macro=true";
import { default as _91id_93H29NpFNKE3DaYpfvlDvVa_45qZvuH5QgsV4obvixbF3NQMeta } from "/opt/build/repo/pages/customers/[id].vue?macro=true";
import { default as index1SPkbjywRoud9w2sBIotfuYOboRLHDRntUZqLHeIHMMMeta } from "/opt/build/repo/pages/customers/index.vue?macro=true";
import { default as newO0iurtR0Unq6Umh47eMRhXSbbPoyKTahuajWCAeICKoMeta } from "/opt/build/repo/pages/customers/new.vue?macro=true";
import { default as _91id_931rgu86RzKxT4s_45N5_45dUyqsCo6Q7H4QoyMvifzlN5xOEMeta } from "/opt/build/repo/pages/discounts/[id].vue?macro=true";
import { default as indexuFPWN4tU4bHkzrRPi02hfF8MKhN7H4cwmq6wNAk5up4Meta } from "/opt/build/repo/pages/discounts/index.vue?macro=true";
import { default as newiBztTEjafWELze48ttO3_452VBltZpBV2ADruxOWbAfP0Meta } from "/opt/build/repo/pages/discounts/new.vue?macro=true";
import { default as _91code_93VOsop6tqfCZPfE0j9XYE633XWWCimRcLsw3b50kDidMMeta } from "/opt/build/repo/pages/events/[handle]/change-seats/[code].vue?macro=true";
import { default as indexRSPQuPzw04r0H91auvPpxGFOSLeZEmt1Cup4UbA6dLUMeta } from "/opt/build/repo/pages/events/[handle]/index.vue?macro=true";
import { default as _91occurrence_93SB_pRCvnuzskDwJEvyFcMLQWmGSowhsbtQq4RE10VZUMeta } from "/opt/build/repo/pages/events/[handle]/seating/[occurrence].vue?macro=true";
import { default as sta4t7m7lfRQ0AO87etEgSCzWl6L_45cEMuhGWP6Dz2i4k9YMeta } from "/opt/build/repo/pages/events/[handle]/sta.vue?macro=true";
import { default as calendarQ7ptHaqRCkI54czprAmF0qzwrBtnv5avjP_FMubGBEsMeta } from "/opt/build/repo/pages/events/calendar.vue?macro=true";
import { default as _91handle_93cHjei6hzBWceglxDw4JNJgF8GVAvKq_450rgi8rEIvvLsMeta } from "/opt/build/repo/pages/events/checkins/[handle].vue?macro=true";
import { default as indexEVOP_aPKk8sfXL_UWlkpAKz5s9qe74EITg8TTFzJMA4Meta } from "/opt/build/repo/pages/events/index.vue?macro=true";
import { default as newXqrcNj_45_bD6Tv5QEPNH4DzSP5KHY2vkSBRTUCWCr9joMeta } from "/opt/build/repo/pages/events/new.vue?macro=true";
import { default as filesnjbqrMjeVJzVxNMF9nwhg_45TVpK7nUw_ONLpCHL1g1YMMeta } from "/opt/build/repo/pages/files.vue?macro=true";
import { default as indexaAs6F0uOO1PbNxqH8DD_45e04gSZQCarVD9TCXJYV94KEMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as inviteyCVTi_45EYxxKy6hl_45BkthflszJVTwyE0GpvcXy1iVos8Meta } from "/opt/build/repo/pages/links/invite.vue?macro=true";
import { default as logindGU_Y4tTcWsCg9HnKfbgxniTJNnqn8Xqf4N3mbSf_459QMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as authorizeVNadGtjAafRBlHdRStDI_34l4Dn_vrBC39GLs_45DwMacMeta } from "/opt/build/repo/pages/oauth/authorize.vue?macro=true";
import { default as forgot_45passwordOt46G_WNhGe_E0mKnvNvANZ9KaR4dOotgk43lUA_hI4Meta } from "/opt/build/repo/pages/oauth/forgot-password.vue?macro=true";
import { default as reset_45passwordlL8Y2rfXzmLAlXpKWEyLxnylCu95SUgKXTEhtFetvLoMeta } from "/opt/build/repo/pages/oauth/reset-password.vue?macro=true";
import { default as indexFPlknJoBjJrRlhEnTGPTAKlG2cBCn3cDruEcJQJrmM4Meta } from "/opt/build/repo/pages/onboarding/index.vue?macro=true";
import { default as new_45storeZxyXFRBtWufL2EnosRjjcv3ZOHgHm0X8xKouW_E0RUkMeta } from "/opt/build/repo/pages/onboarding/new-store.vue?macro=true";
import { default as indexXaf5RenS_E2tVXEKjRvnJjHXui3u_45H2sAy_qct6lQTsMeta } from "/opt/build/repo/pages/orders/[number]/index.vue?macro=true";
import { default as abandoned_45checkoutsBe0lW__xoTmTbdP_45yPPUYrFZ2yQKsme_kFMSh_45EaY_458Meta } from "/opt/build/repo/pages/orders/abandoned-checkouts.vue?macro=true";
import { default as _91id_937mXz6QNQWQLiZIA31NF6NMMv_45umKoormvTRr81G2HPAMeta } from "/opt/build/repo/pages/orders/checkout-links/[id].vue?macro=true";
import { default as indexcLTHq0YBUaF_45rqL7uiQ2t5_45Px3fudC1mMmRD1tAvRJ8Meta } from "/opt/build/repo/pages/orders/checkout-links/index.vue?macro=true";
import { default as newl1H1NrufErwZHLi_fDr8bHe9slZjerHC069kj67a5g4Meta } from "/opt/build/repo/pages/orders/checkout-links/new.vue?macro=true";
import { default as indexszvg7aJBwCSE_454l__45Zr0_xJYKm1V6VqM0DOppkpZ448Meta } from "/opt/build/repo/pages/orders/drafts/index.vue?macro=true";
import { default as newR9gh_45H_45hQPsNKCIujx9rssB8s8QypdxWk_45oBWrCYTP0Meta } from "/opt/build/repo/pages/orders/drafts/new.vue?macro=true";
import { default as indexOlWd9vFHguHOsQSBcDz6x2XeFrcv2t5_45c0Q9Cv_45IO0oMeta } from "/opt/build/repo/pages/orders/index.vue?macro=true";
import { default as index6pC4uV3KK5t46_zAtaYa7AYmpcfDI2Lv1n8QyREWE2IMeta } from "/opt/build/repo/pages/products/[handle]/index.vue?macro=true";
import { default as _91id_930gZZnixRy4qr8JJGndAhpMOjKPQAvkSWGKD0lRn49AAMeta } from "/opt/build/repo/pages/products/[handle]/variant/[id].vue?macro=true";
import { default as _91handle_93kpcB77pWv4LLaJubIas5UgE_y2WIVx0i4cztugfJ_45e4Meta } from "/opt/build/repo/pages/products/collections/[handle].vue?macro=true";
import { default as indexgf10QstV9um11A9Kp6Qkd5GvnJX070cPxs4PXPiquBcMeta } from "/opt/build/repo/pages/products/collections/index.vue?macro=true";
import { default as new1CYA1zKCgPLA0svpWCRNZlfXJBqwzURb_45Ar4XJS3F5gMeta } from "/opt/build/repo/pages/products/collections/new.vue?macro=true";
import { default as indexgINLZMAQB6OSTIcQdOV54n7A4tNmzrKx1ixREiGW9xIMeta } from "/opt/build/repo/pages/products/giftcards/index.vue?macro=true";
import { default as indexxspISwgRyASwNYLmwgNSKrA9PKgH599ragwaFnUpyHIMeta } from "/opt/build/repo/pages/products/index.vue?macro=true";
import { default as inventory7URh_7hqsGPyNl30pq2UcMn7oQwY5X_PGeTYjr0PvvMMeta } from "/opt/build/repo/pages/products/inventory.vue?macro=true";
import { default as newUg_45mdN1lIxFSzLSU_nIrHPVv2xiknU1DR_45WNvX1omQkMeta } from "/opt/build/repo/pages/products/new.vue?macro=true";
import { default as indexDYRTXVcuhzmySK5iIyIOanU3y2xY_45JnbTVMBa_2ExrMMeta } from "/opt/build/repo/pages/products/transfers/index.vue?macro=true";
import { default as registereUFPUgCXbUbkM74KdKqZIrJcc_QOIXBANMYTn5YpRlAMeta } from "/opt/build/repo/pages/register.vue?macro=true";
import { default as _91handle_93trAqP0D64X9vz796uWX0JQezC7E7juyExkVXdCHgVioMeta } from "/opt/build/repo/pages/services/[handle].vue?macro=true";
import { default as _91reference_93jDm6rXUaLM56Pk_45C3m5bGe9p_458ngoaneqtts0hBY5j4Meta } from "/opt/build/repo/pages/services/bookings/[reference].vue?macro=true";
import { default as indexbUK9AmH4N8rksYxBzfkTABkHhFB8BpLL2lAQEdkNjScMeta } from "/opt/build/repo/pages/services/bookings/index.vue?macro=true";
import { default as liveReSgrB_bfoGHlbC3jPhNDOaENRuuLBrrYULNcqLkyGAMeta } from "/opt/build/repo/pages/services/bookings/live.vue?macro=true";
import { default as calendar4twMMKz2hl4mSVxX4nJfg4wouzdRg_uGkG198iJPE9QMeta } from "/opt/build/repo/pages/services/calendar.vue?macro=true";
import { default as indexNg2uRT2j_45xcQ_K73rdRuYPigt_45NQCcqXjkIStwL7EDsMeta } from "/opt/build/repo/pages/services/index.vue?macro=true";
import { default as newT9_2auFQpEUct_45mPM5t_B3skv4o_ahyBj1SaI08taewMeta } from "/opt/build/repo/pages/services/new.vue?macro=true";
import { default as appst2UFSjwowCDL_XUJp7Xq7wPkg9Wk1IYIIsCOyV4efQAMeta } from "/opt/build/repo/pages/settings/apps.vue?macro=true";
import { default as _91id_93FACBqCjuk5rm1Sxy_45BJc0PT6zWpm0FW_45rQ_45Dmcz_45HfUMeta } from "/opt/build/repo/pages/settings/availability/[id].vue?macro=true";
import { default as indexee6QB4RpUR9jQH_455BgT2utHGsdLyJcXAHw_EJOiHws0Meta } from "/opt/build/repo/pages/settings/availability/index.vue?macro=true";
import { default as billing2FwP6YwTamQgo194pMed7LF2_Xg0hvoijms95z1gUs0Meta } from "/opt/build/repo/pages/settings/billing.vue?macro=true";
import { default as generalVc1g_Pi5AacHRcXVCVkwUZMMTjVSdcMr7ri3JyudjMEMeta } from "/opt/build/repo/pages/settings/general.vue?macro=true";
import { default as giftcards9dTFTVjDudc4Wlu5UgXAtHgJr4g514K2YiXsiOrjJvEMeta } from "/opt/build/repo/pages/settings/giftcards.vue?macro=true";
import { default as indexBMgLpZVHkEwgISC3d7BC5R_j_6NqYPrlTcTRs9kz_45KYMeta } from "/opt/build/repo/pages/settings/index.vue?macro=true";
import { default as legalSFTIp51u5cVWQ3D8Vm9WW_4JY6jNIbMNnA7__45JewdtAMeta } from "/opt/build/repo/pages/settings/legal.vue?macro=true";
import { default as _91handle_93DJ_45OeSDIb5pst6Si17Eb_45bCe4yDiVaxE6_XIKnkLdFEMeta } from "/opt/build/repo/pages/settings/locations/[handle].vue?macro=true";
import { default as indexpyVT6_FGKR9EvhFJEOsES_45arr_4598WT63j5g4T8yv170Meta } from "/opt/build/repo/pages/settings/locations/index.vue?macro=true";
import { default as newDnmn9nb4DFXLanIxktxaxARtgb_45JS8UiLWhQQMr8wKEMeta } from "/opt/build/repo/pages/settings/locations/new.vue?macro=true";
import { default as notifications5KLZ_bCQhA7swwf9ue0qRGkUWXAtd4Ec_45suCc3Lvlz0Meta } from "/opt/build/repo/pages/settings/notifications.vue?macro=true";
import { default as payouts4kIFWGEw3_5bzSgI_MW2j8oOhm3ZvQ8cq0O_JbJ47QYMeta } from "/opt/build/repo/pages/settings/payouts.vue?macro=true";
import { default as planyOgqF6Eu_45UZPVHaH3pNVxNNQlXj6HUzvLf8fz_vLXxsMeta } from "/opt/build/repo/pages/settings/plan.vue?macro=true";
import { default as profilexV09CIsF_P7lbtZC5GR7wn1iST2TwlPKytY_xhDnVH4Meta } from "/opt/build/repo/pages/settings/profile.vue?macro=true";
import { default as businessJFJYmnfKc2UJiDIFoQrnZhgFpoBxXP2xIElcb_iWI2YMeta } from "/opt/build/repo/pages/settings/profiles/business.vue?macro=true";
import { default as event6rKXzv798tUHvyYkPLK2T_45z4qKJfoUSksH8CiNg4lK8Meta } from "/opt/build/repo/pages/settings/profiles/event.vue?macro=true";
import { default as servicegSnmFBKKfaKq4Eu1Upp_uDHfzh2qb8IWKZLKbdMmhAwMeta } from "/opt/build/repo/pages/settings/profiles/service.vue?macro=true";
import { default as indexg5WvXgsPDJ7feqa4B1kyrQ_45fY6A8alJjkUlM_45fGPFmwMeta } from "/opt/build/repo/pages/settings/shipping/index.vue?macro=true";
import { default as _91handle_93CliUWwD6hS6BtVUhuqd_45H5_bT0RXXTXU8rqf6Pt_VbcMeta } from "/opt/build/repo/pages/settings/shipping/local-delivery/[handle].vue?macro=true";
import { default as _91handle_93I95Ei8yBiMf_45XJrF8UHAanL_fKBcgOIYgcVYTTpBKxIMeta } from "/opt/build/repo/pages/settings/shipping/local-pickup/[handle].vue?macro=true";
import { default as new2axM70WuT_THff_mZyGcHruZTKPQBnxkCpP6rZ_45CcIMMeta } from "/opt/build/repo/pages/settings/shipping/profiles/new.vue?macro=true";
import { default as indexOWvpvLo9rRVad9AoKkpj9Z4mp9unK7746i6TAIvoH_wMeta } from "/opt/build/repo/pages/settings/team/index.vue?macro=true";
import { default as invitewlcPLtk1hL7PcbeLoHsxG2RnU0J7aCgnNMNoasibjqEMeta } from "/opt/build/repo/pages/settings/team/invite.vue?macro=true";
import { default as _91id_93SChMRMa_lz2s9GQsyEffF_45oT5xcagL8EMrcB_aTjTzQMeta } from "/opt/build/repo/pages/settings/team/member/[id].vue?macro=true";
export default [
  {
    name: "access",
    path: "/access",
    component: () => import("/opt/build/repo/pages/access/index.vue")
  },
  {
    name: "access-pricing",
    path: "/access/pricing",
    meta: pricingoT73xqs6a2HW11UcVEGskeWFr6i045A899ru6KkwKN0Meta || {},
    component: () => import("/opt/build/repo/pages/access/pricing.vue")
  },
  {
    name: "access-subscription-confirm-plan",
    path: "/access/subscription/confirm/:plan()",
    meta: indexmpuAyzkpD2Zzqmxxz0yBWn1S7tWJuiBpl3o0zUjEX88Meta || {},
    component: () => import("/opt/build/repo/pages/access/subscription/confirm/[plan]/index.vue")
  },
  {
    name: "access-subscription-success",
    path: "/access/subscription/success",
    component: () => import("/opt/build/repo/pages/access/subscription/success.vue")
  },
  {
    name: "access-usage-service",
    path: "/access/usage/:service()",
    meta: indexxAO7eYZrKEmA6NPiqwgpcwsp6vrgL9uRaaM2XxaDsMoMeta || {},
    component: () => import("/opt/build/repo/pages/access/usage/[service]/index.vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/opt/build/repo/pages/analytics/index.vue")
  },
  {
    name: "analytics-reports-sales",
    path: "/analytics/reports/sales",
    component: () => import("/opt/build/repo/pages/analytics/reports/sales.vue")
  },
  {
    name: "apps-handle",
    path: "/apps/:handle(.*)*",
    component: () => import("/opt/build/repo/pages/apps/[...handle].vue")
  },
  {
    name: "apps-manage-app",
    path: "/apps/manage/:app()",
    component: () => import("/opt/build/repo/pages/apps/manage/[app].vue")
  },
  {
    name: "apps-store",
    path: "/apps/store",
    meta: storeSfpl5V4nay3WRpIYsuHbme2Ziv0SfNp1_45BGN9v2jZlMMeta || {},
    component: () => import("/opt/build/repo/pages/apps/store.vue")
  },
  {
    name: "bio-sites-handle",
    path: "/bio-sites/:handle()",
    meta: _91handle_934hrL76mN99a5XNsRfirbdIx37mb9gqXU44GNO_45F6_45b4Meta || {},
    component: () => import("/opt/build/repo/pages/bio-sites/[handle].vue")
  },
  {
    name: "bio-sites",
    path: "/bio-sites",
    component: () => import("/opt/build/repo/pages/bio-sites/index.vue")
  },
  {
    name: "customers-id",
    path: "/customers/:id()",
    component: () => import("/opt/build/repo/pages/customers/[id].vue")
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/opt/build/repo/pages/customers/index.vue")
  },
  {
    name: "customers-new",
    path: "/customers/new",
    component: () => import("/opt/build/repo/pages/customers/new.vue")
  },
  {
    name: "discounts-id",
    path: "/discounts/:id()",
    component: () => import("/opt/build/repo/pages/discounts/[id].vue")
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/opt/build/repo/pages/discounts/index.vue")
  },
  {
    name: "discounts-new",
    path: "/discounts/new",
    component: () => import("/opt/build/repo/pages/discounts/new.vue")
  },
  {
    name: "events-handle-change-seats-code",
    path: "/events/:handle()/change-seats/:code()",
    meta: _91code_93VOsop6tqfCZPfE0j9XYE633XWWCimRcLsw3b50kDidMMeta || {},
    component: () => import("/opt/build/repo/pages/events/[handle]/change-seats/[code].vue")
  },
  {
    name: "events-handle",
    path: "/events/:handle()",
    component: () => import("/opt/build/repo/pages/events/[handle]/index.vue")
  },
  {
    name: "events-handle-seating-occurrence",
    path: "/events/:handle()/seating/:occurrence()",
    meta: _91occurrence_93SB_pRCvnuzskDwJEvyFcMLQWmGSowhsbtQq4RE10VZUMeta || {},
    component: () => import("/opt/build/repo/pages/events/[handle]/seating/[occurrence].vue")
  },
  {
    name: "events-handle-sta",
    path: "/events/:handle()/sta",
    meta: sta4t7m7lfRQ0AO87etEgSCzWl6L_45cEMuhGWP6Dz2i4k9YMeta || {},
    component: () => import("/opt/build/repo/pages/events/[handle]/sta.vue")
  },
  {
    name: "events-calendar",
    path: "/events/calendar",
    meta: calendarQ7ptHaqRCkI54czprAmF0qzwrBtnv5avjP_FMubGBEsMeta || {},
    component: () => import("/opt/build/repo/pages/events/calendar.vue")
  },
  {
    name: "events-checkins-handle",
    path: "/events/checkins/:handle()",
    component: () => import("/opt/build/repo/pages/events/checkins/[handle].vue")
  },
  {
    name: "events",
    path: "/events",
    meta: indexEVOP_aPKk8sfXL_UWlkpAKz5s9qe74EITg8TTFzJMA4Meta || {},
    component: () => import("/opt/build/repo/pages/events/index.vue")
  },
  {
    name: "events-new",
    path: "/events/new",
    component: () => import("/opt/build/repo/pages/events/new.vue")
  },
  {
    name: "files",
    path: "/files",
    component: () => import("/opt/build/repo/pages/files.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "links-invite",
    path: "/links/invite",
    component: () => import("/opt/build/repo/pages/links/invite.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logindGU_Y4tTcWsCg9HnKfbgxniTJNnqn8Xqf4N3mbSf_459QMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue")
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    component: () => import("/opt/build/repo/pages/oauth/authorize.vue")
  },
  {
    name: "oauth-forgot-password",
    path: "/oauth/forgot-password",
    meta: forgot_45passwordOt46G_WNhGe_E0mKnvNvANZ9KaR4dOotgk43lUA_hI4Meta || {},
    component: () => import("/opt/build/repo/pages/oauth/forgot-password.vue")
  },
  {
    name: "oauth-reset-password",
    path: "/oauth/reset-password",
    meta: reset_45passwordlL8Y2rfXzmLAlXpKWEyLxnylCu95SUgKXTEhtFetvLoMeta || {},
    component: () => import("/opt/build/repo/pages/oauth/reset-password.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexFPlknJoBjJrRlhEnTGPTAKlG2cBCn3cDruEcJQJrmM4Meta || {},
    component: () => import("/opt/build/repo/pages/onboarding/index.vue")
  },
  {
    name: "onboarding-new-store",
    path: "/onboarding/new-store",
    meta: new_45storeZxyXFRBtWufL2EnosRjjcv3ZOHgHm0X8xKouW_E0RUkMeta || {},
    component: () => import("/opt/build/repo/pages/onboarding/new-store.vue")
  },
  {
    name: "orders-number",
    path: "/orders/:number()",
    component: () => import("/opt/build/repo/pages/orders/[number]/index.vue")
  },
  {
    name: "orders-abandoned-checkouts",
    path: "/orders/abandoned-checkouts",
    component: () => import("/opt/build/repo/pages/orders/abandoned-checkouts.vue")
  },
  {
    name: "orders-checkout-links-id",
    path: "/orders/checkout-links/:id()",
    component: () => import("/opt/build/repo/pages/orders/checkout-links/[id].vue")
  },
  {
    name: "orders-checkout-links",
    path: "/orders/checkout-links",
    meta: indexcLTHq0YBUaF_45rqL7uiQ2t5_45Px3fudC1mMmRD1tAvRJ8Meta || {},
    component: () => import("/opt/build/repo/pages/orders/checkout-links/index.vue")
  },
  {
    name: "orders-checkout-links-new",
    path: "/orders/checkout-links/new",
    component: () => import("/opt/build/repo/pages/orders/checkout-links/new.vue")
  },
  {
    name: "orders-drafts",
    path: "/orders/drafts",
    meta: indexszvg7aJBwCSE_454l__45Zr0_xJYKm1V6VqM0DOppkpZ448Meta || {},
    component: () => import("/opt/build/repo/pages/orders/drafts/index.vue")
  },
  {
    name: "orders-drafts-new",
    path: "/orders/drafts/new",
    component: () => import("/opt/build/repo/pages/orders/drafts/new.vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexOlWd9vFHguHOsQSBcDz6x2XeFrcv2t5_45c0Q9Cv_45IO0oMeta || {},
    component: () => import("/opt/build/repo/pages/orders/index.vue")
  },
  {
    name: "products-handle",
    path: "/products/:handle()",
    component: () => import("/opt/build/repo/pages/products/[handle]/index.vue")
  },
  {
    name: "products-handle-variant-id",
    path: "/products/:handle()/variant/:id()",
    component: () => import("/opt/build/repo/pages/products/[handle]/variant/[id].vue")
  },
  {
    name: "products-collections-handle",
    path: "/products/collections/:handle()",
    component: () => import("/opt/build/repo/pages/products/collections/[handle].vue")
  },
  {
    name: "products-collections",
    path: "/products/collections",
    meta: indexgf10QstV9um11A9Kp6Qkd5GvnJX070cPxs4PXPiquBcMeta || {},
    component: () => import("/opt/build/repo/pages/products/collections/index.vue")
  },
  {
    name: "products-collections-new",
    path: "/products/collections/new",
    component: () => import("/opt/build/repo/pages/products/collections/new.vue")
  },
  {
    name: "products-giftcards",
    path: "/products/giftcards",
    meta: indexgINLZMAQB6OSTIcQdOV54n7A4tNmzrKx1ixREiGW9xIMeta || {},
    component: () => import("/opt/build/repo/pages/products/giftcards/index.vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexxspISwgRyASwNYLmwgNSKrA9PKgH599ragwaFnUpyHIMeta || {},
    component: () => import("/opt/build/repo/pages/products/index.vue")
  },
  {
    name: "products-inventory",
    path: "/products/inventory",
    meta: inventory7URh_7hqsGPyNl30pq2UcMn7oQwY5X_PGeTYjr0PvvMMeta || {},
    component: () => import("/opt/build/repo/pages/products/inventory.vue")
  },
  {
    name: "products-new",
    path: "/products/new",
    component: () => import("/opt/build/repo/pages/products/new.vue")
  },
  {
    name: "products-transfers",
    path: "/products/transfers",
    component: () => import("/opt/build/repo/pages/products/transfers/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registereUFPUgCXbUbkM74KdKqZIrJcc_QOIXBANMYTn5YpRlAMeta || {},
    component: () => import("/opt/build/repo/pages/register.vue")
  },
  {
    name: "services-handle",
    path: "/services/:handle()",
    component: () => import("/opt/build/repo/pages/services/[handle].vue")
  },
  {
    name: "services-bookings-reference",
    path: "/services/bookings/:reference()",
    component: () => import("/opt/build/repo/pages/services/bookings/[reference].vue")
  },
  {
    name: "services-bookings",
    path: "/services/bookings",
    component: () => import("/opt/build/repo/pages/services/bookings/index.vue")
  },
  {
    name: "services-bookings-live",
    path: "/services/bookings/live",
    meta: liveReSgrB_bfoGHlbC3jPhNDOaENRuuLBrrYULNcqLkyGAMeta || {},
    component: () => import("/opt/build/repo/pages/services/bookings/live.vue")
  },
  {
    name: "services-calendar",
    path: "/services/calendar",
    meta: calendar4twMMKz2hl4mSVxX4nJfg4wouzdRg_uGkG198iJPE9QMeta || {},
    component: () => import("/opt/build/repo/pages/services/calendar.vue")
  },
  {
    name: "services",
    path: "/services",
    meta: indexNg2uRT2j_45xcQ_K73rdRuYPigt_45NQCcqXjkIStwL7EDsMeta || {},
    component: () => import("/opt/build/repo/pages/services/index.vue")
  },
  {
    name: "services-new",
    path: "/services/new",
    component: () => import("/opt/build/repo/pages/services/new.vue")
  },
  {
    name: "settings-apps",
    path: "/settings/apps",
    meta: appst2UFSjwowCDL_XUJp7Xq7wPkg9Wk1IYIIsCOyV4efQAMeta || {},
    component: () => import("/opt/build/repo/pages/settings/apps.vue")
  },
  {
    name: "settings-availability-id",
    path: "/settings/availability/:id()",
    meta: _91id_93FACBqCjuk5rm1Sxy_45BJc0PT6zWpm0FW_45rQ_45Dmcz_45HfUMeta || {},
    component: () => import("/opt/build/repo/pages/settings/availability/[id].vue")
  },
  {
    name: "settings-availability",
    path: "/settings/availability",
    meta: indexee6QB4RpUR9jQH_455BgT2utHGsdLyJcXAHw_EJOiHws0Meta || {},
    component: () => import("/opt/build/repo/pages/settings/availability/index.vue")
  },
  {
    name: "settings-billing",
    path: "/settings/billing",
    meta: billing2FwP6YwTamQgo194pMed7LF2_Xg0hvoijms95z1gUs0Meta || {},
    component: () => import("/opt/build/repo/pages/settings/billing.vue")
  },
  {
    name: "settings-general",
    path: "/settings/general",
    meta: generalVc1g_Pi5AacHRcXVCVkwUZMMTjVSdcMr7ri3JyudjMEMeta || {},
    component: () => import("/opt/build/repo/pages/settings/general.vue")
  },
  {
    name: "settings-giftcards",
    path: "/settings/giftcards",
    meta: giftcards9dTFTVjDudc4Wlu5UgXAtHgJr4g514K2YiXsiOrjJvEMeta || {},
    component: () => import("/opt/build/repo/pages/settings/giftcards.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/build/repo/pages/settings/index.vue")
  },
  {
    name: "settings-legal",
    path: "/settings/legal",
    meta: legalSFTIp51u5cVWQ3D8Vm9WW_4JY6jNIbMNnA7__45JewdtAMeta || {},
    component: () => import("/opt/build/repo/pages/settings/legal.vue")
  },
  {
    name: "settings-locations-handle",
    path: "/settings/locations/:handle()",
    meta: _91handle_93DJ_45OeSDIb5pst6Si17Eb_45bCe4yDiVaxE6_XIKnkLdFEMeta || {},
    component: () => import("/opt/build/repo/pages/settings/locations/[handle].vue")
  },
  {
    name: "settings-locations",
    path: "/settings/locations",
    meta: indexpyVT6_FGKR9EvhFJEOsES_45arr_4598WT63j5g4T8yv170Meta || {},
    component: () => import("/opt/build/repo/pages/settings/locations/index.vue")
  },
  {
    name: "settings-locations-new",
    path: "/settings/locations/new",
    meta: newDnmn9nb4DFXLanIxktxaxARtgb_45JS8UiLWhQQMr8wKEMeta || {},
    component: () => import("/opt/build/repo/pages/settings/locations/new.vue")
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    meta: notifications5KLZ_bCQhA7swwf9ue0qRGkUWXAtd4Ec_45suCc3Lvlz0Meta || {},
    component: () => import("/opt/build/repo/pages/settings/notifications.vue")
  },
  {
    name: "settings-payouts",
    path: "/settings/payouts",
    meta: payouts4kIFWGEw3_5bzSgI_MW2j8oOhm3ZvQ8cq0O_JbJ47QYMeta || {},
    component: () => import("/opt/build/repo/pages/settings/payouts.vue")
  },
  {
    name: "settings-plan",
    path: "/settings/plan",
    meta: planyOgqF6Eu_45UZPVHaH3pNVxNNQlXj6HUzvLf8fz_vLXxsMeta || {},
    component: () => import("/opt/build/repo/pages/settings/plan.vue")
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    meta: profilexV09CIsF_P7lbtZC5GR7wn1iST2TwlPKytY_xhDnVH4Meta || {},
    component: () => import("/opt/build/repo/pages/settings/profile.vue")
  },
  {
    name: "settings-profiles-business",
    path: "/settings/profiles/business",
    meta: businessJFJYmnfKc2UJiDIFoQrnZhgFpoBxXP2xIElcb_iWI2YMeta || {},
    component: () => import("/opt/build/repo/pages/settings/profiles/business.vue")
  },
  {
    name: "settings-profiles-event",
    path: "/settings/profiles/event",
    meta: event6rKXzv798tUHvyYkPLK2T_45z4qKJfoUSksH8CiNg4lK8Meta || {},
    component: () => import("/opt/build/repo/pages/settings/profiles/event.vue")
  },
  {
    name: "settings-profiles-service",
    path: "/settings/profiles/service",
    meta: servicegSnmFBKKfaKq4Eu1Upp_uDHfzh2qb8IWKZLKbdMmhAwMeta || {},
    component: () => import("/opt/build/repo/pages/settings/profiles/service.vue")
  },
  {
    name: "settings-shipping",
    path: "/settings/shipping",
    meta: indexg5WvXgsPDJ7feqa4B1kyrQ_45fY6A8alJjkUlM_45fGPFmwMeta || {},
    component: () => import("/opt/build/repo/pages/settings/shipping/index.vue")
  },
  {
    name: "settings-shipping-local-delivery-handle",
    path: "/settings/shipping/local-delivery/:handle()",
    meta: _91handle_93CliUWwD6hS6BtVUhuqd_45H5_bT0RXXTXU8rqf6Pt_VbcMeta || {},
    component: () => import("/opt/build/repo/pages/settings/shipping/local-delivery/[handle].vue")
  },
  {
    name: "settings-shipping-local-pickup-handle",
    path: "/settings/shipping/local-pickup/:handle()",
    meta: _91handle_93I95Ei8yBiMf_45XJrF8UHAanL_fKBcgOIYgcVYTTpBKxIMeta || {},
    component: () => import("/opt/build/repo/pages/settings/shipping/local-pickup/[handle].vue")
  },
  {
    name: "settings-shipping-profiles-new",
    path: "/settings/shipping/profiles/new",
    meta: new2axM70WuT_THff_mZyGcHruZTKPQBnxkCpP6rZ_45CcIMMeta || {},
    component: () => import("/opt/build/repo/pages/settings/shipping/profiles/new.vue")
  },
  {
    name: "settings-team",
    path: "/settings/team",
    meta: indexOWvpvLo9rRVad9AoKkpj9Z4mp9unK7746i6TAIvoH_wMeta || {},
    component: () => import("/opt/build/repo/pages/settings/team/index.vue")
  },
  {
    name: "settings-team-invite",
    path: "/settings/team/invite",
    meta: invitewlcPLtk1hL7PcbeLoHsxG2RnU0J7aCgnNMNoasibjqEMeta || {},
    component: () => import("/opt/build/repo/pages/settings/team/invite.vue")
  },
  {
    name: "settings-team-member-id",
    path: "/settings/team/member/:id()",
    meta: _91id_93SChMRMa_lz2s9GQsyEffF_45oT5xcagL8EMrcB_aTjTzQMeta || {},
    component: () => import("/opt/build/repo/pages/settings/team/member/[id].vue")
  }
]
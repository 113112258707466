<template>
  <LiveBooking class="h-100vh" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LiveBooking from "~/components/pages/live-bookings/LiveBooking.vue";

export default defineComponent({
  components: { LiveBooking },
  setup() {
    definePageMeta({
      layout: "canvas",
    });
  },
});
</script>

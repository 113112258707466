<template>
  <v-container>
    <RequiresProfileService v-if="requiresProfile" class="mb-4" />

    <PageHeading
      title="Services"
      subtitle="Create services to share for people to book on your calendar."
      :actions="actions"
      actions-namespace="services"
      broadcast-actions
    />

    <ServicesList
      class="mt-10"
      :groups="items"
      :loading="loading"
      :refetching="refetching"
      :saving="saving"
    />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RequiresProfileService from "~/components/pages/services/RequiresProfileService.vue";
import ServicesList from "~/components/pages/services/ServicesList";
import { Service, ServiceGroup } from "~/models/dobby/Service";
import { Rule } from "~/services/Rules";
import { SimpleEditor, ColorInput } from "#components";
import { useToast } from "vue-toastification";
import { debounce } from "lodash";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";

export default defineComponent({
  components: { RequiresProfileService, ServicesList },
  setup() {
    definePageMeta({
      rememberNav: "Services",
    });

    const { handle } = useNamespacedEvent("services");
    const { wrapConfirmInput } = useConfirm();
    const { del } = useDeleteModel(Service);
    const toast = useToast();
    const profile = useTransaction<any>("service-profile");

    type ShuffleServiceInput = {
      id: string;
      position: number;
      services: string[];
    };

    const shuffle = useMutation<
      { wsShuffleService: boolean },
      { data: ShuffleServiceInput[] }
    >(gql`
      mutation ($data: [ShuffleServiceInput!]!) {
        wsShuffleService(data: $data)
      }
    `);

    const { loading, refetching, items, refetch, add } =
      useListData<ServiceGroup>({
        query: ServiceGroup.include("services", [
          "name",
          "provider",
          "handle",
          "status",
          "duration",
          "private",
          "position",
        ]),
        actions: {
          namespace: "services",
          removeOne: "group:remove",
        },
      });

    const requiresProfile = computed(() => {
      return !profile.fetching.value && !profile.original.value.exists;
    });

    const actions = computed(() => {
      return defineActions([
        {
          title: "More options",
          items: [
            {
              title: "Update service profile",
              key: "profile",
              to: { name: "settings-profiles-service" },
            },
            {
              title: "Manage availability",
              key: "availability",
              to: { name: "settings-availability" },
            },
            {
              title: "Copy URL",
              key: "url",
              disabled: profile.loading.value,
            },
          ],
        },
        {
          title: "Add group",
          key: "add:group",
          disabled: loading.value,
        },
        {
          title: "Add service",
          key: "add:service",
          disabled: !items.value.length,
          primary: true,
        },
      ]);
    });

    const groupInputs = defineFormInputs({
      name: {
        name: "Name",
        rules: Rule.group([Rule.required(), Rule.title()]),
        type: "text",
        props: {
          placeholder: "e.g. Hair Services",
        },
      },
      color: {
        name: "Appointment color",
        rules: Rule.required(),
        component: ColorInput,
      },
      description: {
        name: "Description",
        rules: Rule.string.max(1000),
        component: SimpleEditor,
        props: {
          outlined: true,
          counter: 1000,
          height: 150,
        },
      },
    });

    const addGroup = wrapConfirmInput(
      async (data) => {
        toast.info("Creating service group");

        await ServiceGroup.create(data)
          .then((group) => {
            toast.success(`Created service group ${group.name}`);
            add(group);
          })
          .catch(() => {
            toast.error(`Failed to create service group ${data.name}`);
          });
      },
      {
        title: "Add a new group",
        subtitle: "Create a new group for your service collections.",
        doneText: "Create",
        inputs: groupInputs,
        inputsDefaults: {
          color: "#969696",
        },
      }
    );

    const editGroup = wrapConfirmInput(
      async (data, group) => {
        toast.info(`Updating service group ${group.name}`);
        const p = group.$update(data);

        if (p === false) {
          return;
        }

        await p
          .then(() => {
            toast.success(`Updated service group ${group.name}`);
          })
          .catch(() => {
            toast.error(`Failed to update service group ${group.name}`);
          });
      },
      (group: ServiceGroup) => ({
        title: `Update ${group.name}`,
        doneText: "Save",
        inputs: groupInputs,
        inputsDefaults: {
          name: group.name,
          color: group.color,
          description: group.description,
        },
      })
    );

    const getUrl = useCopyPageUrl(
      "Service URL",
      () => `https://orie.market/services/${profile.data.value.handle}`
    );

    const delService = afterEach(del, (r) => {
      if (r !== false) {
        refetch();
      }
    });

    handle("url", getUrl);
    handle("add:group", addGroup);
    handle("group:edit", editGroup);
    handle("service:remove", delService);
    handle("service:url", (service: Service) => {
      const url = service.$url();
      if (url) {
        navigator.clipboard.writeText(url);
        toast.info("URL copied to clipboard");
      }
    });

    const forceUpdate = debounce(forceUpdatesShallowRef(items));

    const updateShuffled = debounce(() => {
      const data = items.value.map((group) => ({
        id: group.id,
        position: group.position,
        services: sortBy(group.services, "position").map((s) => s.handle),
      }));
      shuffle.mutate({ data });
    }, 2000);

    handle("shuffled", forceUpdate);
    handle("shuffled", updateShuffled);

    return {
      requiresProfile,
      items,
      loading,
      refetching,
      saving: shuffle.loading,
      actions,
    };
  },
});
</script>

<template>
  <v-row class="h-full" align-content="center" justify="center">
    <v-col class="text-center" cols="11" md="5">
      <v-card class="pa-10" rounded="xl">
        <div class="flex justify-center mb-4">
          <LogoChip size="50" />
        </div>
        <div class="text-h6">
          Looks like you can't create <br />{{
            featureType ? "any" : "anymore"
          }}
          {{ resource }}.
        </div>
        <p class="text-body mt-5">
          This might be due to your current plan. Upgrade to a different plan or
          select a plan
          {{
            featureType
              ? "which allows this resource"
              : "with more resource allocation"
          }}
          to be able to create more {{ resource }}.
        </p>
        <v-card-actions class="justify-center">
          <v-btn variant="flat" size="large" @click="$router.go(-1)">
            <span class="mr-2">Go back</span>
          </v-btn>
          <v-btn variant="flat" size="large" :to="{ name: 'access-pricing' }">
            <span class="mr-2">Pick a plan</span>
            <v-icon>arrow_right_alt</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    definePageMeta({
      layout: "blank",
    });

    const route = useRoute();

    const service = String(route.params.service);

    const { usage } = useServiceUsage();

    const featureType = computed(() => {
      return (
        usage.value[service] &&
        (usage.value[service].type == "feature" ||
          typeof usage.value[service].limit == "boolean")
      );
    });

    onMounted(() => {
      if (!usage.value[service]) {
        //   error({ statusCode: 404 })
        navigateTo("/");
      }
    });

    return {
      featureType,
      service,
    };
  },
  computed: {
    resource() {
      if (
        [
          "events",
          "reports",
          "accounts",
          "products",
          "services",
          "giftcards",
        ].includes(this.service)
      )
        return this.service;

      if (this.service === "qr-codes") return "QR codes";
      if (this.service === "cloud-storage") return "files";
      if (this.service === "manual-orders") return "manual orders";
      if (this.service === "cloud-storage") return "files";
      if (this.service === "sales-channels") return "sales channels";
      if (this.service === "inventory-locations") return "locations";
      if (this.service === "custom-shipping-rates")
        return "custom shipping rates";

      return "of this resource";
    },
  },
});
</script>

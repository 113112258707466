<template>
  <div>
    <PageHeading title="Applications" class="mb-4" no-return>
      <v-btn :to="{ name: 'apps-store' }" class="text-none" variant="tonal"
        >App store</v-btn
      >
    </PageHeading>
    <InstalledApps />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InstalledApps from "~/components/pages/settings/applications/InstalledApps.vue";

export default defineComponent({
  components: { InstalledApps },
  setup() {
    definePageMeta({
      layout: "settings",
      rememberNav: "Installed apps",
    });
  },
});
</script>

<template>
  <NewStore @submit="submit" :loading="loading" />
</template>

<script lang="ts">
import NewStore from "@/components/pages/new-store";
import type { SubmitForm } from "~/components/pages/new-store/types";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { notify } from "@kyvg/vue3-notification";
import type { Subscription } from "zen-observable-ts";

export default defineComponent({
  components: { NewStore },
  setup() {
    definePageMeta({
      layout: "canvas",
    });

    const subscription = ref<Subscription>();
    const newStore = ref<string>();
    const { switchTo } = useWorkspace();

    const { loading, mutate } = useMutation<
      { createStore: any },
      { data: SubmitForm }
    >(gql`
      mutation ($data: CreateStoreInput!) {
        createStore(data: $data) {
          name
          code
        }
      }
    `);

    return {
      loading,
      subscription,
      newStore,
      mutate,
      switchTo,
    };
  },
  methods: {
    submit(form: SubmitForm) {
      this.mutate({
        data: form,
      })
        .then((data) => {
          this.newStore = data?.data?.createStore.code;
          // this.$auth.refreshTokens()
          this.waitForWorkspaceSetup(data?.data?.createStore.code);
        })
        .catch((error) => {
          console.error(error);
          notify({
            // group: "app",
            title: "Store",
            type: "error",
            text: `Error creating your store`,
          });
        });
    },
    waitForWorkspaceSetup(workspace: string) {
      const stillWaiting = () => {
        this.$loading(true, "Still preparing workspace");

        // Exit wait after a while.
        setTimeout(() => this.exitWait(), 1000 * 60 * 5);
      };

      this.$loading(true, "Preparing workspace");

      this.subscribeStoreReady(workspace);

      setTimeout(stillWaiting, 5000);
    },
    subscribeStoreReady(workspace: string) {
      if (this.subscription) return;

      const observer = this.$apollo.subscribe({
        query: gql`
          subscription storeWorkspaceReady($workspace: String!) {
            storeWorkspaceReady(workspace: $workspace) {
              code
            }
          }
        `,
        variables: {
          workspace,
        },
      });

      this.subscription = observer.subscribe({
        next: (data) => this.exitWait(),
        error(error) {
          console.error(error);
        },
      });
    },
    unsubscribe() {
      this.subscription?.unsubscribe();
    },
    exitWait() {
      this.unsubscribe();
      this.$auth
        .fetchUser()
        .then((_user) => {
          //
        })
        .catch((error) => {
          //
        })
        .finally(() => {
          if (this.newStore) {
            this.switchTo(this.newStore);
          }
        });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
});
</script>

<template>
  <div>
    <PageHeading title="Giftcards" class="mb-4" no-return />
    <AutoExpiration />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AutoExpiration from "~/components/pages/settings/giftcards/AutoExpiration.vue";

export default defineComponent({
  components: { AutoExpiration },
  setup() {
    definePageMeta({
      layout: "settings",
    });
  },
});
</script>

<template>
  <OverlayLoader class="h-full" :loading="fetching" loader-size="100">
    <BioSiteEditor @exit="close" />
  </OverlayLoader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useBioSiteStore } from "~/layers/admin/stores/bio-site";
import { BioSite } from "~/layers/admin/models";

export default defineComponent({
  setup() {
    definePageMeta({
      layout: "canvas",
    });

    const route = useRoute();
    const router = useRouter();
    const handle = computed(() => String(route.params.handle));

    // TODO: use the same logic as seating.vue
    const store = useBioSiteStore();
    const { fetching } = storeToRefs(store);

    store.setHandle(handle.value);

    const getBioSite = async () => {
      fetching.value = true;
      const site = await BioSite.selectAll().findUnique({
        handle: handle.value,
      });
      fetching.value = false;
      if (!site) {
        // context.root.$nuxt.error({ statusCode: 404 })
        throw new Error("404");
      }
      store.load(site!.getTransfer());
    };

    const close = () => {
      if (history.length) {
        router.back();
      } else {
        router.push({ name: "bio-sites" });
      }
    };

    onMounted(getBioSite);
    watch(handle, getBioSite);

    return {
      fetching,
      close,
    };
  },
});
</script>

<template>
  <div>
    <h2 class="text-6 font-semibold line-height-tight">
      Get started by creating your free seller account today. No credit card
      required.
    </h2>

    <AccountRegistration :intent="intent" />

    <div class="mt-2 caption">
      <div>
        Already have an account?
        <router-link :to="{ name: 'login' }">Login</router-link>
      </div>
      <div>
        By signing up, I agree to Orie
        <a :href="links.terms" target="_blank">Terms of Service</a>.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AccountRegistration from "~/components/pages/register/AccountRegistration.vue";

export default defineComponent({
  components: { AccountRegistration },
  setup() {
    definePageMeta({
      layout: "register",
      auth: false,
      middleware: "redirect-login",
    });

    const route = useRoute();
    const { links } = useAppConfig();

    const intent = route.query.intent ? String(route.query.intent) : undefined;

    return {
      intent,
      links,
    };
  },
});
</script>

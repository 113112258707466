<template>
  <v-container>
    <PageHeading
      title="Inventory"
      actions-namespace="inventory"
      broadcast-actions
      no-return
    />
    <div class="mt-4">
      <div class="flex justify-end">
        <LocationSelect
          v-model="location"
          class="md:max-w-300px"
          density="compact"
          variant="solo"
          rounded="lg"
          mandatory
          hide-details
        />
      </div>
      <DataTable
        class="mt-4"
        v-bind="table"
        :loading="loading"
        :items="items"
      />
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Inventory from "~/models/dobby/Inventory";
import VariantProperty from "../../components/pages/inventory/VariantProperty.vue";
import PolicyProperty from "~/components/pages/inventory/PolicyProperty.vue";
import StockProperty from "~/components/pages/inventory/StockProperty.vue";
import EditProperty from "~/components/pages/inventory/EditProperty.vue";

export default defineComponent({
  setup() {
    definePageMeta({
      rememberNav: "Product inventory",
    });

    const { handle } = useNamespacedEvent("inventory");
    const location = ref<string>();

    const { items, loading } = useListData({
      query: Inventory.select(["id", "sku", "hs_code", "policy"])
        .include("inventoryLevels", (query) =>
          query.selectAll().include("location", ["handle", "name"])
        )
        .include("variant", (query) =>
          query
            .select(["id", "name"])
            .include("product", ["handle", "kind", "name"])
        ),
      actions: {
        namespace: "collections",
        removeOne: "collection:delete",
      },
    });

    const forceUpdate = forceUpdatesShallowRef(items);

    handle("forceUpdate", forceUpdate);

    const table = computed(() => {
      return defineTable({
        searchable: true,
        applyFilters: true,
        mobile: true,
        actionsNamespace: "inventory",

        emptyIcon: "ic:outline-inventory-2",
        emptyTitle: "Manage product inventory",

        views: [
          {
            id: "all",
            title: "All",
            emptyDescription:
              "You have no products stocked at any of your locations.",
            filters: (item: Inventory) => {
              const inLoc = item?.inventoryLevels?.find(
                (level) => location.value === level.location.handle
              );
              return !!inLoc;
            },
          },
          {
            id: "out-of-stock",
            title: "Out of stock",
            emptyTitle: "Nothing here",
            emptyDescription: "You have no products stocked at this location.",
            filters: (item: Inventory) => {
              const level = item?.inventoryLevels?.find(
                (level) => location.value === level.location.handle
              );
              if (!level) return false;
              return !level.available || level.available <= 0;
            },
          },
        ],

        columns: [
          {
            title: "Product",
            sortable: true,
            key: "variantName",
            component: VariantProperty,
          },
          { title: "SKU", sortable: true, key: "sku", type: "title" },
          {
            title: "Policy",
            sortable: true,
            key: "policy",
            component: PolicyProperty,
          },
          {
            title: "Incoming",
            sortable: true,
            key: "incoming",
            component: {
              component: StockProperty,
              props: { location: location.value },
            },
          },
          {
            title: "Available",
            sortable: true,
            key: "available",
            component: {
              component: StockProperty,
              props: { location: location.value },
            },
          },
          {
            title: "Edit stock",
            sortable: false,
            key: "stock",
            component: {
              component: EditProperty,
              props: { location: location.value },
            },
          },
        ],
      });
    });

    return {
      items,
      loading,
      location,
      table,
    };
  },
});
</script>

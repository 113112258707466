<template>
  <v-sheet height="100%">
    <div class="page-container">
      <PageHeading
        title="Featured apps"
        subtitle="Streamline work, save time, and use the right tool for your business."
      />
      <div class="mt-10">
        <ContentLoader :loading="loading">
          <v-row>
            <v-col v-for="app in apps" :key="app.handle" md="6" class="h-full">
              <v-sheet class="app-sheet flex! pa-4 h-full" @click="open(app)">
                <div class="mr-4">
                  <OrieAppIcon :app="app" />
                </div>
                <div>
                  <h2 class="text-subtitle-2">{{ app.name }}</h2>
                  <div
                    v-if="app.short_description"
                    v-html="app.short_description"
                  />
                  <div v-if="app.tags" class="mt-2">
                    <v-chip
                      v-for="tag in app.tags"
                      :key="tag"
                      class="ml-1"
                      size="small"
                      label
                    >
                      {{ tag }}
                    </v-chip>
                  </div>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </ContentLoader>

        <v-navigation-drawer
          v-model="drawer"
          width="400"
          :permanent="installing || uninstalling"
          location="right"
          temporary
          absolute
        >
          <template v-if="selectedApp">
            <div class="highlight px-4 pt-10 pb-4 flex">
              <div class="mr-4">
                <OrieAppIcon :app="selectedApp" />
              </div>
              <div>
                <h2 class="text-h6 mb-2">{{ selectedApp.name }}</h2>
                <v-btn
                  v-if="!selectedApp.installed"
                  color="primary"
                  @click="install(selectedApp)"
                  :loading="installing"
                  >Install app</v-btn
                >
                <v-btn
                  v-else-if="!selectedApp.required"
                  @click="uninstall(selectedApp)"
                  :loading="uninstalling"
                  >Uninstall
                </v-btn>
              </div>
            </div>
            <v-divider />
            <div class="pa-4">
              <template v-if="selectedApp.short_description">
                <div class="mb-4" v-html="selectedApp.short_description"></div>
                <v-divider />
              </template>
            </div>
            <v-list>
              <v-list-item title="Pricing" subtitle="Free" />
              <v-list-item title="Support" subtitle="support@orie.market" />
            </v-list>
          </template>
        </v-navigation-drawer>
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import OrieAppIcon from "@/components/elements/OrieAppIcon.vue";
import { AppInstallStatus, type App } from "~/models/types";

const FETCH_QUERY = gql`
  query Apps {
    wsApps {
      name
      handle
      short_description
      description
      installed
      required
      image
      icon
    }
  }
`;

const INSTALL_MUTATION = gql`
  mutation ($where: AppWhereUniqueInput!) {
    wsInstallApp(where: $where) {
      status
      redirect
    }
  }
`;

const UNINSTALL_MUTATION = gql`
  mutation ($where: AppWhereUniqueInput!) {
    wsUninstallApp(where: $where) {
      handle
    }
  }
`;

export default defineComponent({
  components: { OrieAppIcon },
  setup() {
    definePageMeta({
      rememberNav: "App store",
    });

    const { result, loading, refetch } = useQuery(FETCH_QUERY);
    const drawer = ref(false);
    const installing = ref(false);
    const uninstalling = ref(false);
    const selectedApp = ref<App>();

    const apps = computed<App[]>(() => result.value?.wsApps || []);

    watch(apps, () => {
      drawer.value = false;
    });

    return {
      apps,
      loading,
      drawer,
      installing,
      uninstalling,
      selectedApp,
      refetchApps: refetch,
    };
  },
  methods: {
    open(app: App) {
      this.selectedApp = app;
      this.drawer = true;
    },
    install(app?: App) {
      if (!app) {
        return;
      }
      this.installing = true;
      this.$apollo
        .mutate<{
          wsInstallApp: {
            status: AppInstallStatus;
            redirect?: string;
          };
        }>({
          mutation: INSTALL_MUTATION,
          variables: {
            where: {
              handle: app.handle,
            },
          },
        })
        .then(({ data }) => {
          const status = data!.wsInstallApp.status;
          const redirect = data!.wsInstallApp.redirect;
          if (status === AppInstallStatus.INSTALLED) {
            this.$toast.success("App installed");
            setTimeout(() => {
              if (redirect && isFullpath(redirect)) {
                location.href = redirect;
              } else if (redirect) {
                this.$router.push(redirect);
              } else {
                this.$router.push({
                  name: "apps-app",
                  params: { app: app.handle },
                });
              }
            }, 1000);
          } else if (redirect && status === AppInstallStatus.AUTHORIZE) {
            location.href = redirect;
          } else {
            this.$toast.error("Failed to install app");
          }
        })
        .catch((error) => {
          this.$toast.error("Failed to install app");
        })
        .finally(() => {
          this.installing = false;
        });
    },
    uninstall(app?: App) {
      if (!app) {
        return;
      }
      this.uninstalling = true;
      this.$apollo
        .mutate({
          mutation: UNINSTALL_MUTATION,
          variables: {
            where: {
              handle: app.handle,
            },
          },
        })
        .then(() => {
          this.$toast.success("App uninstalled");
          this.refetchApps();
        })
        .catch(() => {
          this.$toast.error("Failed to uninstall app");
        })
        .finally(() => {
          this.uninstalling = false;
        });
    },
  },
});
</script>

<style lang="scss">
.app-sheet {
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  &:hover {
    background: rgba(112, 117, 123, 0.2);
  }
}
</style>

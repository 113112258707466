<template>
  <div class="text-h6">Change Password</div>
  <p class="text-body mt-5 mb-4">Reset your seller account password</p>

  <v-dynamic-form
    v-model="credentials"
    v-model:valid="valid"
    :inputs="inputs"
    :readonly="loading"
  />

  <v-btn
    @click="reset"
    class="mt-4"
    color="primary"
    :loading="loading"
    :disabled="!valid"
    size="large"
    block
    >Change Password</v-btn
  >
  <v-alert
    v-if="error"
    class="my-2"
    border="end"
    type="error"
    colored-border
    density="compact"
    variant="tonal"
  >
    Invalid credentials
  </v-alert>
</template>

<script lang="ts">
import { Rule } from "~/services/Rules";
import { PasswordInputField } from "#components";
import { useToast } from "vue-toastification";
import {
  AuthResponseStatus,
  type NewPasswordWithCodeInput,
} from "~/layers/admin/modules/auth/runtime/types/api";

export default defineComponent({
  setup() {
    definePageMeta({
      layout: "auth",
      auth: false,
    });

    // page title: "Reset Password"

    const { links } = useAppConfig();
    const router = useRouter();
    const route = useRoute();
    const auth = useAuth();
    const toast = useToast();
    const loader = useLoading();

    const loading = ref(false);
    const error = ref(false);
    const credentials = ref<NewPasswordWithCodeInput>({
      token: String(route.query.token),
      email: String(route.query.email),
      password: "",
      password_confirmation: "",
    });

    const reset = () => {
      loading.value = true;
      error.value = false;

      if (credentials.value) {
        const input = toRaw(credentials.value);
        auth
          .updateForgottenPassword(input)
          .then((response) => {
            if (response?.status !== AuthResponseStatus.PASSWORD_UPDATED) {
              error.value = true;
            } else {
              toast.success("Password reset successful");
              loader.loading(true, "Redirecting to login");
              setTimeout(() => {
                loader.loading(false);
                router.push({ name: "login" });
              }, 2000);
            }
            loading.value = false;
          })
          .catch(() => {
            toast.error("Failed to update password");
            error.value = true;
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    onMounted(() => {
      if (!route.query.token || !route.query.email) {
        router.replace({ name: "login" });
      }
    });

    return {
      links,
      loading,
      error,
      credentials,
      reset,
    };
  },
  data: () => ({
    valid: false,
    inputs: defineFormInputs({
      email: {
        name: "Email",
        type: "text",
        class: "mb-2",
        props: {
          readonly: true,
        },
      },
      password: {
        name: "Password",
        rules: Rule.group([Rule.required(), Rule.password()]),
        component: PasswordInputField,
        class: "mb-2",
        props: {
          hint: "Use 8 or more characters with a mix of letters, numbers & symbols",
          persistentHint: true,
        },
      },
      password_confirmation: {
        name: "Password confirmation",
        rules: Rule.group([Rule.required(), Rule.confirmed("@password")]),
        component: PasswordInputField,
      },
    }),
  }),
});
</script>

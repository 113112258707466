import type { Attributes } from "@moirei/dobby";
import type Event from "../Event";
import type Product from "../Product";
import type Service from "../Service";
import type { EventTicket } from "../Event";
import type { ServiceVariant } from "../Service";
import type ProductVariant from "../ProductVariant";

export const updatesProductPricing = (
  model: Product | ProductVariant | EventTicket | Service | ServiceVariant,
  data: Attributes
) => {
  if (model.pricing && model.pricing.$isDeepDirty()) {
    data.pricing = model.pricing.$toJson();
  }
  if (model.comparison_pricing && model.comparison_pricing.$isDeepDirty()) {
    data.comparison_pricing = model.comparison_pricing.$toJson();
  }

  // for Product and ProductVariant only
  if (model.whole_sale_pricing && model.whole_sale_pricing.$isDeepDirty()) {
    data.whole_sale_pricing = model.whole_sale_pricing.$toJson();
  }
};

export const createsWithProductMedia = (
  model: Product | Event | Service,
  data: Attributes
) => {
  if (model.image && model.image.$shouldUpload()) {
    data.image = { upload: { file: model.image.$getUpload() } };
  } else if (model.image && model.image.$willDelete) {
    data.image = { delete: true };
  }
  if (model.gallery && model.gallery.length) {
    data.gallery = {
      set: model.gallery
        .filter((media) => !media.$willDelete && model.$exists())
        .map((media) => media.id),
    };
  }
};

export const updatesProductMedia = (
  model: Product | Event | Service,
  data: Attributes
) => {
  if (model.image && model.image.$shouldUpload()) {
    data.image = { upload: { file: model.image.$getUpload() } };
  } else if (model.image && model.image.$willDelete) {
    data.image = { delete: true };
  }

  if (model.gallery && model.gallery.length) {
    data.gallery = {
      // set: model.gallery
      //   .filter((media) => !media.$willDelete && media.$exists())
      //   .map((media) => media.id),
      // attach: model.gallery
      //   .filter((media) => !media.$willDelete && media.$exists())
      //   .map((media) => media.id),
      detach: model.gallery
        .filter((media) => media.$willDelete && media.$exists())
        .map((media) => media.id),
    };

    const uploads = model.gallery
      .filter((media) => media.$shouldUpload())
      .map((media) => media.$getUpload());

    if (uploads.length) {
      data.gallery.upload = { files: uploads };
    }
  }
};

export const updatesOrderForm = (
  model: Product | Event | Service,
  data: Attributes
) => {
  const orderForm = model.$getSaveContextAttribute("order_form");
  if (orderForm?.isDirty) {
    if (orderForm.data) {
      const saveData = Object.assign({}, orderForm.data);
      if (!saveData.id) {
        saveData.id = uuid();
      }
      data.order_form = { set: saveData };
    } else if (orderForm.original) {
      data.order_form = { delete: true };
    }
  }
  // if (model.order_form) {
  //   if (model.order_form.$willDelete) {
  //     data.order_form = { delete: true };
  //   } else if (model.order_form.$isDeepDirty()) {
  //     const orderForm = model.order_form.$toJson();
  //     if (!orderForm.id) {
  //       orderForm.id = uuid();
  //     }
  //     data.order_form = { set: orderForm };
  //   }
  // }
  return data;
};

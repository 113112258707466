import type { Attributes } from "@moirei/dobby";
import Event from "../Event";
import {
  creatingEventSection,
  updatingEventSection,
} from "./event-section-hooks";
import {
  createsWithProductMedia,
  updatesOrderForm,
  updatesProductMedia,
} from "./common";
import {
  creatingEventOccurrence,
  updatingEventOccurrence,
} from "./event-occurrence.hooks";
import { omit } from "lodash";

export const creatingEvent = (model: Event, data: Attributes) => {
  // unset ID. Tempt used by UI to track instances
  model.$setChangesAttribute("id", undefined);

  createsWithProductMedia(model, data);

  if (data.type) {
    data.type = data.type;
  }

  if (
    model.schedule &&
    model.schedule.$isDirty() &&
    model.schedule.$isValid()
  ) {
    data.schedule = model.schedule.$toJson();
  }
  if (
    model.availability &&
    model.availability.$isDirty() &&
    model.availability.$isValid()
  ) {
    data.availability = model.availability.$toJson();
  }

  if (model.sections) {
    data.sections = model.sections
      .filter((section) => section.$isDirty())
      .map((section) =>
        creatingEventSection(section, section.$getAttributes())
      );
  }

  if (data.occurrences) {
    data.occurrences = data.occurrences.map((occur: any) => {
      if (occur.date) {
        occur.starts_at = formatToDatetime(parseDateLike(occur.date[0]));
        occur.ends_at = formatToDatetime(parseDateLike(occur.date[1]));
      }
      return omit(occur, ["date"]);
    });
  }

  if (model.order_form && model.order_form.$isDirty()) {
    data.order_form = model.order_form.$toJson();
  }

  return data;
};

export const updatingEvent = (model: Event, data: Attributes) => {
  if (data.type) {
    data.type = { connect: data.type };
  }

  updatesProductMedia(model, data);

  if (
    model.schedule &&
    model.schedule.$isDirty() &&
    model.schedule.$isValid()
  ) {
    data.schedule = model.schedule.$toJson();
  }

  if (
    model.availability &&
    model.availability.$isDirty() &&
    model.availability.$isValid()
  ) {
    data.availability = model.availability.$toJson();
  }

  if (model.sections) {
    data.sections = {
      create: model.sections
        .filter(
          (section) =>
            !section.$willDelete && section.$isDeepDirty() && !section.$exists()
        )
        .map((section) => creatingEventSection(section)),
      update: model.sections
        .filter(
          (section) =>
            !section.$willDelete && section.$isDeepDirty() && section.$exists()
        )
        .map((section) => updatingEventSection(section)),
      delete: model.sections
        .filter((section) => section.$willDelete && section.$exists())
        .map((section) => section.id),
    };
  }

  if (model.occurrences) {
    data.occurrences = {
      create: model.occurrences
        .filter(
          (occurrence) =>
            !occurrence.$willDelete &&
            occurrence.$isDeepDirty() &&
            !occurrence.$exists()
        )
        .map((occurrence) => creatingEventOccurrence(occurrence)),
      update: model.occurrences
        .filter(
          (occurrence) =>
            !occurrence.$willDelete &&
            occurrence.$isDeepDirty() &&
            occurrence.$exists()
        )
        .map((occurrence) => updatingEventOccurrence(occurrence)),
      delete: model.occurrences
        .filter((occurrence) => occurrence.$willDelete && occurrence.$exists())
        .map((occurrence) => occurrence.id),
    };
  }

  if (model.agenda) {
    data.agenda = model.agenda
      .filter((item) => !item.$willDelete)
      .map((item) => item.$toJson());
  }

  if (model.faqs) {
    data.faqs = model.faqs
      .filter((item) => !item.$willDelete)
      .map((item) => item.$toJson());
  }

  updatesOrderForm(model, data);

  return data;
};

<template>
  <div>
    <PageHeading title="Payouts" no-return />
    <AccountStatus class="mt-4" />
    <Balances class="mt-4" />
    <PayoutSchedules class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AccountStatus from "~/components/account-alerts/AccountStatus.vue";
import Balances from "~/components/pages/settings/payouts/Balances.vue";
import PayoutSchedules from "~/components/pages/settings/payouts/PayoutSchedules.vue";

export default defineComponent({
  components: { AccountStatus, Balances, PayoutSchedules },
  setup() {
    definePageMeta({
      layout: "settings",
      rememberNav: "Payouts",
    });
  },
});
</script>

<template>
  <div>
    <PageHeading title="General" class="mb-4" no-return />
    <StoreDetails />
    <StoreAddress class="mt-4" />
    <StoreCurrency class="mt-4" />
    <StoreStandards class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import StoreDetails from "~/components/pages/settings/general/StoreDetails.vue";
import StoreAddress from "~/components/pages/settings/general/StoreAddress.vue";
import StoreCurrency from "~/components/pages/settings/general/StoreCurrency.vue";
import StoreStandards from "~/components/pages/settings/general/Standards.vue";

export default defineComponent({
  components: { StoreDetails, StoreAddress, StoreCurrency, StoreStandards },
  setup() {
    definePageMeta({
      layout: "settings",
      rememberNav: "General settings",
    });
  },
});
</script>

<template>
  <div>
    <PageHeading title="Availability" class="mb-4" no-return />
    <ScheduleAvailability
      :schedules="mySchedules"
      :loading="loading"
      title="Personal availability"
      headline="Configure times when <b>you</b> are available for bookings."
      empty-title="Manage personal schedules"
      empty-text="You have no personal availability schedules created"
      add-action="add:personal"
    />
    <ScheduleAvailability
      :schedules="teamSchedules"
      :loading="loading"
      class="mt-4"
      title="Team availability"
      headline="Configure times when your <b>team</b> is available for bookings."
      empty-title="Manage team schedules"
      empty-text="You have no availability schedules created for your teams"
      add-action="add:team"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ScheduleAvailability from "~/components/pages/settings/availability/ScheduleAvailability.vue";
import { ServiceSchedule } from "~/models/dobby/Service";
import { Rule } from "~/services/Rules";

export default defineComponent({
  components: { ScheduleAvailability },
  setup() {
    definePageMeta({
      layout: "settings",
      rememberNav: "Availability",
    });

    const scheduleQuery = ServiceSchedule.defaultQuery()
      .add("mine")
      .policy("cache-first");

    const { handle } = useNamespacedEvent("settings:schedules");
    const { wrapConfirmInput } = useConfirm();
    const { wrapLoading } = useLoading();

    const { items: schedules, loading } =
      useListQuery<ServiceSchedule>(scheduleQuery);

    const mySchedules = computed(() =>
      schedules.value.filter((schedule) => schedule.mine)
    );
    const teamSchedules = computed(() =>
      schedules.value.filter((schedule) => schedule.workspace)
    );

    type AddFormInput = {
      name: string;
    };

    const add = wrapLoading(async (team: boolean, input: AddFormInput) => {
      const schedule = await ServiceSchedule.defaultQuery().create({
        ...input,
        workspace: !!team,
      });
      schedules.value.push(schedule);
    }, "Saving");

    const confirmation = {
      doneText: "Save",
      width: 500,
      inputs: defineFormInputs({
        name: {
          name: "Name",
          rules: Rule.group([Rule.required(), Rule.title()]),
          type: "text",
          props: {
            placeholder: "e.g. Working Hours",
          },
        },
      }),
    };

    const addPersonal = wrapConfirmInput(
      (input: AddFormInput) => add(false, input),
      () => ({
        ...confirmation,
        title: "Add a new personal schedule",
      })
    );

    const addTeam = wrapConfirmInput(
      (input: AddFormInput) => add(true, input),
      () => ({
        ...confirmation,
        title: "Add a new team schedule",
      })
    );

    handle("added", (schedule: ServiceSchedule) => {
      schedules.value.push(schedule);
    });

    handle("deleted", (schedule: ServiceSchedule) => {
      schedules.value = schedules.value.filter((sch) => sch.id !== schedule.id);
    });

    handle("add:personal", addPersonal);
    handle("add:team", addTeam);

    return {
      loading,
      mySchedules,
      teamSchedules,
    };
  },
});
</script>

<template>
  <div>
    <PageHeading title="Notifications" no-return />
    <PushNotifications class="mt-4" />
    <Preferences class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PushNotifications from "~/components/pages/settings/notifications/PushNotifications.vue";
import Preferences from "~/components/pages/settings/notifications/Preferences.vue";

export default defineComponent({
  components: { PushNotifications, Preferences },
  setup() {
    definePageMeta({
      layout: "settings",
    });
  },
});
</script>

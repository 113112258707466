<template>
  <OverlayLoader
    :loading="fetching"
    loader-size="100"
    style="display: flex; flex-flow: column; height: 100%"
  >
    <div class="pl-4 pr-2 pt-2" style="flex: 0 1 auto">
      <div class="flex">
        <h3>Pick seat{{ currentSeats.length > 1 ? "s" : "" }}</h3>
        <v-spacer />
        <v-btn @click="back" variant="text" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </div>

      <div v-if="ticketHasEmail">
        <v-checkbox
          v-model="emailTicket"
          label="Email updated tickets to"
          hide-details
        />
      </div>
    </div>

    <PickSeats
      style="flex: 1 1 auto"
      v-if="eventMap"
      :event-map="eventMap"
      :current-seats="currentSeats"
      :loading-map="fetching"
      :saving="saving"
      :available-seats="availableSeats"
      :max-seats="maxSeats"
      @seats-selected="selectSeats"
    />
  </OverlayLoader>
</template>

<script lang="ts">
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useToast } from "vue-toastification";
import PickSeats from "~/components/pages/event-seating/pick-seats";
import Event from "~/models/dobby/Event";
import type {
  IEventBooking,
  IEventBookingTicket,
  IEventOccurrence,
} from "~/models/interfaces/Event";

export default defineComponent({
  components: { PickSeats },
  setup() {
    definePageMeta({
      layout: "canvas",
    });

    const route = useRoute();
    const toast = useToast();

    const handle = computed(() => String(route.params.handle));
    const code = computed(() => String(route.params.code));

    const eventName = ref();
    const eventMap = ref();
    const currentSeats = ref<string[]>([]);
    const availableSeats = ref<string[]>([]);
    const emailTicket = ref(false);
    const ticketHasEmail = ref(false);
    const maxSeats = ref(0);

    const { fetching, onResult } = useModelQuery<Event>(Event, (query) => {
      query
        .select(["name", "occurrence_type"])
        .include("bookings", (bookingsQuery) => {
          return bookingsQuery
            .select("id")
            .include("tickets", (ticketsQuery) => {
              return ticketsQuery
                .select(["code", "seats", "email"])
                .include("eventTicket", ["max_seats"]);
            })
            .include("eventOccurrence", [
              "id",
              "reserved_seating",
              "event_map",
              "available_seats",
            ]);
        });

      return {
        handle: handle.value,
      };
    });

    const { loading: saving, mutate: save } = useMutation(gql`
      mutation (
        $where: EventWhereUniqueInput!
        $code: String!
        $seats: [String!]!
        $emailTicket: Boolean!
      ) {
        wsChangeEventTicketSeats(
          where: $where
          code: $code
          seats: $seats
          emailTicket: $emailTicket
        ) {
          code
        }
      }
    `);

    onResult((event) => {
      const occurrences = new Map<string, IEventOccurrence>();

      eventName.value = event.name;
      const bookings: IEventBooking[] = event.bookings || [];

      let ticket: IEventBookingTicket | undefined;

      // find ticket
      for (const booking of bookings) {
        for (const item of booking.tickets!) {
          if (item.code == code.value) {
            ticket = item;
            occurrences.set(
              booking.eventOccurrence.id,
              booking.eventOccurrence
            );
            break;
          }
        }
      }

      if (occurrences.size !== 1) {
        toast.error("Invalid event occurrence found for bookings");
        return;
      }

      const occurrence = Array.from(occurrences.values())[0];
      const reservedSeating = !!occurrence.reserved_seating;
      availableSeats.value = occurrence.available_seats || [];

      if (ticket) {
        currentSeats.value = ticket.seats || [];
        ticketHasEmail.value = !!ticket.email;
        maxSeats.value = ticket.eventTicket?.max_seats || 1;
      }

      if (!reservedSeating) {
        toast.error("Event does not have reserved seating. Exiting.", {
          onClose: close,
        });
        return;
      }

      if (occurrence.event_map) {
        eventMap.value = occurrence.event_map;
      }
    });

    return {
      currentSeats,
      handle,
      code,
      eventMap,
      fetching,
      availableSeats,
      saving,
      emailTicket,
      save,
      ticketHasEmail,
      maxSeats,
    };
  },
  methods: {
    selectSeats(selection: string[]) {
      this.save({
        where: { handle: this.handle },
        code: this.code,
        seats: selection,
        emailTicket: this.emailTicket,
      })
        .then(() => {
          this.$toast.success("Seats changed");
          setTimeout(() => this.back(), 3000);
        })
        .catch((error) => {
          this.$toast.error("Failed to change seats");
        });
    },
    back() {
      if (history.length) {
        this.$router.back();
      } else {
        this.$router.push({
          name: "events-handle",
          params: { handle: this.handle },
        });
      }
    },
  },
});
</script>

import { integer, string } from "@moirei/dobby";
import Model from "./Model";
import { PeriodIntervalUnit } from "../enums";
import type { IPeriodInterval } from "../interfaces/Period";

export const periodIntervalDefaults = {
  count: 1,
  unit: PeriodIntervalUnit.YEAR,
};

export class PeriodInterval extends Model implements IPeriodInterval {
  static override entity = "PeriodInterval";
  @integer() declare count: number;
  @string() declare unit: PeriodIntervalUnit;

  $isValid() {
    return this.count && this.count > 0 && this.unit;
  }
}

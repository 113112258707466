<template>
  <div>
    <div class="flex justify-center my-8">
      <LogoChip />
    </div>
    <v-card v-if="freePlan" class="mb-4">
      <v-alert
        border="start"
        color="primary"
        density="compact"
        variant="text"
        colored-border
      >
        <div class="flex-align">
          <div class="justify-space-around">
            <div class="text-subtitle-2">Free plan</div>
            <div class="caption">
              If you need some time to decide which plan is best for your
              business, you can start with our free plan
            </div>
          </div>
          <v-spacer />
          <v-btn
            size="small"
            variant="flat"
            :to="{
              name: 'access-subscription-confirm-plan',
              params: { plan: freePlan.handle },
            }"
          >
            Start with free plan <v-icon>arrow_right_alt</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </v-card>

    <v-row v-if="loading">
      <v-col v-for="i in 3" :key="i">
        <v-card class="py-5" elevation="1">
          <v-skeleton-loader type="article" />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else class="justify-content-center">
      <v-col v-for="plan in sortedPlans" :key="plan.handle" cols="12" md="4">
        <v-card class="py-5" elevation="1">
          <v-chip
            v-if="plan.recommended"
            class="recommended-chip"
            color="primary"
            size="small"
            >Recommended</v-chip
          >
          <div class="text-center mt-4">
            <div class="text-h6">{{ plan.name }}</div>
            <p class="mt-2">{{ plan.caption }}</p>
            <div class="my-4">
              AUD <span class="text-h4">{{ plan.monthly_subscription }}</span
              >/mo
            </div>
            <v-btn
              color="primary"
              variant="flat"
              :to="{
                name: 'access-subscription-confirm-plan',
                params: { plan: plan.handle },
              }"
              >Choose plan</v-btn
            >
          </div>
          <v-divider class="my-4" />
          <div>
            <div class="text-overline mb-2 px-4">Features</div>
            <div
              class="plan-feature flex caption mt-1 px-4"
              v-for="(feature, i) in plan.features"
              :key="`${plan.id}:${feature.name}:${i}`"
            >
              {{ feature.name }}
              <v-spacer />
              <span class="font-weight-medium">
                <span v-if="feature.caption">{{ feature.caption }}</span>
                <v-icon
                  v-else-if="feature.value === true"
                  color="green"
                  class="ml-1"
                  size="small"
                  >check</v-icon
                >
                <v-icon
                  v-else-if="feature.value === false"
                  color="grey"
                  class="ml-1"
                  size="small"
                  >close</v-icon
                >
                <span v-else>{{ feature.value }}</span>
              </span>
            </div>
          </div>
        </v-card>
        <!-- <merchant-plan-item-mini
          :active="active"
          @click="toggle"
          v-bind="plan"
          :plan-id="plan.id"
          :subscription-charge="plan.monthly_subscription"
          subscription-currency="A$"
          subscription-interval="month"
          class="red ma-0"
        /> -->
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import gql from "graphql-tag";

const planQuery = `
  id name handle nickname caption description featured recommended monthly_subscription
  charges{ id name nickname statement description type percentage measure_type summaries currency{ code symbol } }

  features { id name nickname statement description caption value }
  #features { id name nickname statement description caption presentment_position icon type untracked value group{ id name } }
`;

export default defineComponent({
  setup() {
    definePageMeta({
      layout: "blank",
    });
  },
  data: () => ({
    platformPlans: [] as any[],
    freePlan: undefined as any | undefined,
  }),
  computed: {
    sortedPlans() {
      return (this.platformPlans || [])
        .sort((a, b) => a.monthly_subscription - b.monthly_subscription)
        .filter((plan) => plan.featured)
        .map((plan) => {
          plan.features = plan.features.map((feature: any) => {
            if (feature.value) {
              try {
                feature.value = JSON.parse(feature.value);
              } catch (error) {
                console.log("JSON.parse error", feature.value);
              }
            }
            return feature;
          });
          return plan;
        });
    },
    loading() {
      return !this.platformPlans || !this.platformPlans.length;
      // return this.$apollo.queries.platformPlans.loading
    },
  },
  apollo: {
    platformPlans: {
      query: gql`
        query {
          platformPlans: publicPlatformPlans { ${planQuery} }
        }
      `,
      fetchPolicy: "no-cache",
    },
    freePlan: {
      query: gql`
        query ($where: PublicPlatformPlanWhereUniqueInput!) {
          freePlan: publicPlatformPlan(where: $where) { ${planQuery} }
        }
      `,
      variables: {
        where: { handle: "free" },
      },
    },
  },
});
</script>

<style lang="scss">
.recommended-chip {
  position: absolute;
  top: -10px;
  left: calc(50% - 50px);
}
.plan-feature {
  transition: background-color 300ms;
  &:hover {
    background-color: rgba(12, 6, 6, 0.05);
  }
}
</style>

<template>
  <div>
    <PageHeading
      class="mt-4"
      title="Orie Plan: "
      :return-to="{ name: 'access-pricing' }"
      :subtitle="planDescription"
    >
      <template #append-title>
        <span class="opacity-70 ml-2">{{ planTitle }}</span>
      </template>
    </PageHeading>
    <ConfirmPlanPage class="mt-10" :handle="handle" :pk="pk" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ConfirmPlanPage from "~/components/pages/plan-subscription/confirm/ConfirmPlanPage.vue";
import { useConfirmPlanStore } from "~/components/pages/plan-subscription/confirm/store";

export default defineComponent({
  components: { ConfirmPlanPage },
  setup() {
    definePageMeta({
      layout: "blank",
      middleware: "select-plan",
    });

    const config = useRuntimeConfig();
    const route = useRoute();
    const store = useConfirmPlanStore();
    const { planTitle, hasNoPaymentItems, plan } = storeToRefs(store);

    const handle = String(route.params.plan);
    const planDescription = computed(() => plan.value.description);

    return {
      pk: config.public.stripe.pk,
      handle,
      planTitle,
      hasNoPaymentItems,
      planDescription,
    };
  },
});
</script>

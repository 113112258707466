import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_z7z1a6yg8I_zyThBqUBObV4FLqf2r0KIi0JDPHuedaQ from "/opt/build/repo/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_TLJV_ZOrmcF5eWWMgHgwvrMmBSP_x_MiTTHMkfm2xus from "/opt/build/repo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/opt/build/repo/.nuxt/unocss.mjs";
import plugin_Z2NAJmGc0N_X_nVYF03tKb_4w6wF_UikSGt3Yt2U8Hw from "/opt/build/repo/layers/admin/modules/metrics/runtime/plugin.ts";
import plugin__8tRKNfud1gPIf9ie3ipOncvM0pT3QVopgldXNlh9rM from "/opt/build/repo/layers/admin/modules/media-library/runtime/plugin.ts";
import plugin_N5masrIgn10SKAD72juDUuZu0FRSj2XDxwSdzbnvHOU from "/opt/build/repo/layers/admin/modules/auth/runtime/plugin.ts";
import loading_5n4dcE2GaxYAwUq5m6HTTcFLbgOB71_HWtL4vJHAQOk from "/opt/build/repo/layers/ui/plugins/loading.ts";
import vee_validate_tVM4dwXcOd8UaSXqGW8pkF5OquKPxkg0Q_hhTLrLdT4 from "/opt/build/repo/layers/ui/plugins/vee-validate.ts";
import app_action_9Exv8N9XGFm6Qeju_Hmsb06lBim2Jt6Jwn_BA2clFxQ from "/opt/build/repo/layers/admin/plugins/app-action.ts";
import shortcuts_XQFVAbAS4eJgWVtQSJJeWXDAOmX2sHtqGlgNRA0MQfA from "/opt/build/repo/layers/admin/plugins/shortcuts.ts";
import vue_toastification_VQDyvJX6t9sT0cZWIzeRZ1pj6gM_wf95SL9d2dC_smc from "/opt/build/repo/layers/admin/plugins/vue-toastification.ts";
import apollo_mgb5iL8pimUkqqhu5n66JRv5YLad_Ana7KlAEOw6cu0 from "/opt/build/repo/plugins/apollo.ts";
import dobby_r_6uV3sTaRjEzKvmzqyBddjj7hjzH_Kh2PefeTuzLhg from "/opt/build/repo/plugins/dobby.ts";
import global_app_action_handler_KrdXKLHV5JEYaWjFj8usf168BSoQXRhjy3WVCoVGSc4 from "/opt/build/repo/plugins/global-app-action-handler.ts";
import notification_39G7B0_YCmpNv_nt_nEo3ElsiXNgdEaT2rCDcQo_8tw from "/opt/build/repo/plugins/notification.ts";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "/opt/build/repo/plugins/vuetify.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_z7z1a6yg8I_zyThBqUBObV4FLqf2r0KIi0JDPHuedaQ,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_TLJV_ZOrmcF5eWWMgHgwvrMmBSP_x_MiTTHMkfm2xus,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  plugin_Z2NAJmGc0N_X_nVYF03tKb_4w6wF_UikSGt3Yt2U8Hw,
  plugin__8tRKNfud1gPIf9ie3ipOncvM0pT3QVopgldXNlh9rM,
  plugin_N5masrIgn10SKAD72juDUuZu0FRSj2XDxwSdzbnvHOU,
  loading_5n4dcE2GaxYAwUq5m6HTTcFLbgOB71_HWtL4vJHAQOk,
  vee_validate_tVM4dwXcOd8UaSXqGW8pkF5OquKPxkg0Q_hhTLrLdT4,
  app_action_9Exv8N9XGFm6Qeju_Hmsb06lBim2Jt6Jwn_BA2clFxQ,
  shortcuts_XQFVAbAS4eJgWVtQSJJeWXDAOmX2sHtqGlgNRA0MQfA,
  vue_toastification_VQDyvJX6t9sT0cZWIzeRZ1pj6gM_wf95SL9d2dC_smc,
  apollo_mgb5iL8pimUkqqhu5n66JRv5YLad_Ana7KlAEOw6cu0,
  dobby_r_6uV3sTaRjEzKvmzqyBddjj7hjzH_Kh2PefeTuzLhg,
  global_app_action_handler_KrdXKLHV5JEYaWjFj8usf168BSoQXRhjy3WVCoVGSc4,
  notification_39G7B0_YCmpNv_nt_nEo3ElsiXNgdEaT2rCDcQo_8tw,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds
]
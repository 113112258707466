<template>
  <div>
    <AppContextualSaveBar />

    <PageHeading
      :title="title"
      :subtitle="displayTime"
      actions-namespace="schedule"
    >
      <template #under-title>
        <router-link
          v-if="original.teamMember"
          :to="{
            name: 'settings-team-member-id',
            params: { id: original.teamMember.id },
          }"
          class="text-3 font-semibold link"
        >
          Owned by {{ original.teamMember.name }}
        </router-link>
      </template>
    </PageHeading>

    <ScheduleDetails class="mt-4" />
    <ScheduleAvailabilityEdit class="mt-4" />
    <ScheduleDateOverridesEdit class="mt-4" />

    <div class="flex gap-2 mt-4">
      <v-spacer />
      <v-btn
        @click="del"
        :loading="deleting"
        :disabled="!canEdit"
        color="red"
        elevation="1"
        >Delete
      </v-btn>
      <v-btn
        @click="save"
        :loading="saving"
        :disabled="!canSave"
        color="primary"
        elevation="1"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ScheduleDetails from "~/components/pages/settings/availability/ScheduleDetails.vue";
import ScheduleAvailabilityEdit from "~/components/pages/settings/availability/ScheduleAvailabilityEdit.vue";
import ScheduleDateOverridesEdit from "~/components/pages/settings/availability/ScheduleDateOverridesEdit.vue";
import { ServiceSchedule } from "~/models/dobby/Service";

export default defineComponent({
  components: {
    ScheduleDetails,
    ScheduleAvailabilityEdit,
    ScheduleDateOverridesEdit,
  },
  setup() {
    definePageMeta({
      layout: "settings",
    });

    const route = useRoute();
    const { handle } = useNamespacedEvent("schedule");

    const id = String(route.params.id);

    const { original, canSave, canEdit, fetching, saving, loading, save } =
      useSaveContext<any>({
        refetchOnSave: true,
        query: () => {
          const query = ServiceSchedule.selectAll()
            .include("teamMember", ["id", "name"])
            .include("availability");
          return query.findUnique({ id });
        },
      });

    const title = computed(() => original.value.name || `Schedule #${id}`);
    const displayTime = computed(() =>
      original.value.availability
        ? formatScheduleTime(original.value.availability)
        : undefined
    );

    const { del, deleting } = useSaveContextDeleteModel({
      model: ServiceSchedule,
      routeOnDelete: { name: "settings-availability" },
    });

    handle("save", save);

    return {
      title,
      displayTime,
      fetching,
      saving,
      loading,
      canSave,
      canEdit,
      deleting,
      original,
      save,
      del,
    };
  },
});
</script>

<template>
  <div>
    <PageHeading
      title="Members and permissions"
      subtitle="Manage what team members can see or do in your workspace."
      no-return
    />
    <WorkspaceOwner class="mt-4" />
    <Members id="members" class="mt-4" />
    <Permissions id="permissions" class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WorkspaceOwner from "~/components/pages/settings/team/WorkspaceOwner.vue";
import Members from "~/components/pages/settings/team/Members.vue";
import Permissions from "~/components/pages/settings/team/Permissions.vue";

export default defineComponent({
  components: { WorkspaceOwner, Members, Permissions },
  setup() {
    definePageMeta({
      layout: "settings",
      rememberNav: "Team members",
    });
  },
});
</script>

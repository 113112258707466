<template>
  <div>
    <PageHeading title="Billing" no-return />
    <AccountStatus class="mt-4" />
    <BankAccounts class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AccountStatus from "~/components/account-alerts/AccountStatus.vue";
import BankAccounts from "~/components/pages/settings/billing/BankAccounts.vue";

export default defineComponent({
  components: { AccountStatus, BankAccounts },
  setup() {
    definePageMeta({
      layout: "settings",
      rememberNav: "Billing",
    });
  },
});
</script>

import type { Attributes } from "@moirei/dobby";
import { omit } from "lodash";
import Product from "../Product";
import {
  creatingProductOption,
  updatingProductOption,
} from "./product-option-hook";
import { upsertingVariant, updatingVariantWithId } from "./variant-hooks";
import {
  createsWithProductMedia,
  updatesOrderForm,
  updatesProductMedia,
  updatesProductPricing,
} from "./common";
import type { SaveContextCustomFill } from "~/layers/admin/helpers/fill-model";

export const creatingProduct = (model: Product, data?: Attributes) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  createsWithProductMedia(model, data);
  updatesProductPricing(model, data);

  if (model.inventory) {
    data.inventory = {
      ...model.inventory.$getAttributeChanges(),
      inventoryLevels: model.inventory.inventoryLevels?.map((level) => ({
        available: level.available,
        location: {
          handle: level.location.handle,
        },
      })),
    };
  }

  if (model.$isVariationKind()) {
    if (model.variants && model.variants.length) {
      data.variants = model.variants.map((variant) =>
        upsertingVariant(variant)
      );
    }
    // omit variation defaults (option1, pricing, etc.)
    data = omit(data, [
      "pricing",
      "cost_per_item",
      "barcode",
      "taxable",
      "availability",
      "enable_comparison_pricing",
      "weight",
      "weight_unit",
      "fulfillment_service",
      "inventory_management",
      "pricing",
      "comparison_pricing",
      "whole_sale_pricing",
      "inventory",
    ]);
  }

  if (model.options && model.options.length) {
    data.options = model.options
      .filter((option) => option.$isDeepDirty())
      .map((option) => creatingProductOption(option));
  }

  if (model.faqs) {
    data.faqs = model.faqs
      .filter((item) => !item.$willDelete)
      .map((item) => item.$toJson());
  }

  if (model.order_form && model.order_form.$isDirty()) {
    data.order_form = model.order_form.$toJson();
  }

  return data;
};

export const updatingProduct = (model: Product, data?: Attributes) => {
  if (!data) {
    data = model.$getAttributeChanges();
  }

  updatesProductMedia(model, data);
  updatesProductPricing(model, data);

  const members = model.$getSaveContextAttribute("booking_members");
  if (members?.isDirty) {
    data.booking_members = members.data;
  }

  if (model.options && model.options.length) {
    data.options = model.options
      .filter((option) => option.$isDeepDirty())
      .map((option) => option.$toJson());
  }
  if (model.inventory && model.inventory.$isDeepDirty()) {
    data.inventory = {
      ...model.inventory.$getAttributeChanges(),
      inventoryLevels: model.inventory.inventoryLevels?.map((level) => ({
        available: level.available,
        location: {
          handle: level.location.handle,
        },
      })),
    };
  }

  if (model.variants && model.variants.length) {
    data.variants = {
      create: model.variants
        .filter(
          (variant) =>
            !variant.$willDelete && variant.$isDeepDirty() && !variant.$exists()
        )
        .map((variant) => upsertingVariant(variant)),
      update: model.variants
        .filter(
          (variant) =>
            !variant.$willDelete && variant.$isDeepDirty() && variant.$exists()
        )
        .map((variant) => updatingVariantWithId(variant)),
      delete: model.variants
        .filter((variant) => variant.$willDelete && variant.$exists())
        .map((variant) => variant.id),
    };
  }

  if (model.options && model.options.length) {
    data.options = {
      create: model.options
        .filter(
          (option) =>
            !option.$willDelete && option.$isDeepDirty() && !option.$exists()
        )
        .map((option) => creatingProductOption(option)),
      update: model.options
        .filter(
          (option) =>
            !option.$willDelete && option.$isDeepDirty() && option.$exists()
        )
        .map((option) => updatingProductOption(option)),
      delete: model.options
        .filter((option) => option.$willDelete && option.$exists())
        .map((option) => option.id),
    };
  }

  if (model.faqs) {
    data.faqs = model.faqs
      .filter((item) => !item.$willDelete)
      .map((item) => item.$toJson());
  }

  if (model.booking_order_limits && model.booking_order_limits.$isDirty()) {
    data.booking_order_limits = model.booking_order_limits.$toJson();
  }

  if (
    model.booking_duration_limits &&
    model.booking_duration_limits.$isDirty()
  ) {
    data.booking_duration_limits = model.booking_duration_limits.$toJson();
  }

  if (data.booking_schedule_id) {
    data.booking_schedule = data.booking_schedule_id;
  }

  updatesOrderForm(model, data);

  return omit(data, ["booking_schedule_id"]);
};

<template>
  <div>
    <PageHeading title="Shipping & delivery" no-return />
    <ShippingProfiles class="mt-4" />
    <LocalDelivery class="mt-4" />
    <LocalPickup class="mt-4" />
    <Packages class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShippingProfiles from "~/components/pages/settings/shipping/ShippingProfiles.vue";
import LocalDelivery from "~/components/pages/settings/shipping/LocalDelivery.vue";
import LocalPickup from "~/components/pages/settings/shipping/LocalPickup.vue";
import Packages from "~/components/pages/settings/shipping/Packages.vue";

export default defineComponent({
  components: { ShippingProfiles, LocalDelivery, LocalPickup, Packages },
  setup() {
    definePageMeta({
      layout: "settings",
    });
  },
});
</script>

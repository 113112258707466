<template>
  <div>
    <PageHeading title="Plan" no-return />
    <AccountStatus class="mt-4" />
    <WorkspacePlan class="mt-4" />
    <UsageQuotas class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AccountStatus from "~/components/account-alerts/AccountStatus.vue";
import WorkspacePlan from "~/components/pages/settings/plan/WorkspacePlan.vue";
import UsageQuotas from "~/components/pages/settings/plan/UsageQuotas.vue";

export default defineComponent({
  components: { AccountStatus, WorkspacePlan, UsageQuotas },
  setup() {
    definePageMeta({
      layout: "settings",
    });
  },
});
</script>

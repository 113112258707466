<template>
  <div>
    <PageHeading title="Legal" class="mb-4" no-return />
    <LegalLinks />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LegalLinks from "~/components/pages/settings/LegalLinks.vue";

export default defineComponent({
  components: { LegalLinks },
  setup() {
    definePageMeta({
      layout: "settings",
    });
  },
});
</script>

import { boolean, id, integer, model, string } from "@moirei/dobby";
import { Model } from "~/layers/admin/models/dobby";
import type { OrderFormInputType, OrderFormType } from "~/layers/admin/models";
import type {
  IOrderForm,
  IOrderFormQuestion,
  IOrderFormQuestionOption,
} from "../interfaces/OrderForm";
import { isNil, omitBy } from "lodash";

export class OrderForm extends Model implements IOrderForm {
  static override entity = "OrderForm";
  static override queryAttributes = ["*"];
  static override queryRelationships = ["questions"];

  constructor(attributes = {}) {
    super({
      questions: [],
      ...omitBy(attributes, isNil), // omit nil values for defaults above
    });
  }

  @id() declare readonly id: string;
  @string() declare type: OrderFormType;
  @boolean() declare disabled: boolean;
  @boolean() declare allow_edits: boolean;
  @integer() declare time_limit?: number;
  @string({ list: true }) declare instructions?: string[];
  @model(() => OrderFormQuestion, { list: true })
  declare questions: OrderFormQuestion[];
}

export class OrderFormQuestion extends Model implements IOrderFormQuestion {
  static override entity = "OrderFormQuestion";
  static override queryAttributes = ["*"];
  static override queryRelationships = ["options"];

  @id() declare readonly id: string;
  @integer() declare position?: number;
  @string() declare question: string;
  @boolean() declare optional?: boolean;
  @string() declare type: OrderFormInputType;
  @boolean() declare limit_multi_choice?: boolean;
  @string() declare additional_terms?: string;
  @model(() => OrderFormQuestionOption, { list: true })
  declare options: OrderFormQuestionOption[];
}

export class OrderFormQuestionOption
  extends Model
  implements IOrderFormQuestionOption
{
  static override entity = "OrderFormQuestionOption";
  static override queryAttributes = ["*"];

  @string() declare value: string;
  @integer() declare available_quantity?: number;
}

<template>
  <v-container>
    <PageHeading
      title="Checkout links"
      :actions="actions"
      actions-namespace="checkout-links"
      broadcast-actions
      no-return
    />
    <DataTable class="mt-10" v-bind="table" :loading="loading" :items="items" />
  </v-container>
</template>

<script lang="ts">
import { TextCopy } from "#components";
import { defineComponent } from "vue";
import { CheckoutLink } from "~/models/dobby/Checkout";

export default defineComponent({
  setup() {
    definePageMeta({
      rememberNav: "Checkout Links",
    });

    const { items, loading } = useListData({
      query: CheckoutLink.select([
        "id",
        "name",
        "mode",
        // "expires_at",
        "web_url",
        "disabled",
      ]).include("checkouts", ["token"]),
    });

    return {
      items,
      loading,
    };
  },
  data: () => ({
    actions: defineActions([
      {
        title: "Create checkout link",
        key: "add:checkout-link",
        primary: true,
      },
    ]),

    table: defineTable({
      searchable: true,
      applyFilters: true,
      actionsNamespace: "checkout-links",

      itemRoute: (item: CheckoutLink) => ({
        name: "orders-checkout-links-id",
        params: { id: item.id },
      }),

      emptyIcon: "heroicons:shopping-cart",
      emptyTitle: "Manage checkout pages",
      emptyDescription:
        "Sell products, offer subscriptions, or accept donations with a link.",

      columns: [
        {
          title: "Link URL",
          sortable: false,
          key: "web_url",
          component: {
            component: TextCopy,
            useItemValue: true,
            usePropKey: "text",
          },
        },
        { text: "Active", sortable: false, key: "active", type: "active" },
        { title: "Name", sortable: true, key: "name", type: "title" },
        { title: "Payment type", sortable: true, key: "mode", type: "chip" },
        { title: "Price", sortable: true, key: "price", type: "price" },
        {
          title: "Checkouts",
          sortable: false,
          key: "checkouts",
          type: "count",
        },
        { title: "Created", sortable: true, key: "created_at", type: "date" },
      ],

      itemActions: [
        {
          title: "Duplicate",
          key: "checkout-link:duplicate",
        },
        {
          title: "Deactivate",
          key: "checkout-link:deactivate",
          color: "red",
        },
      ],
    }),
  }),
});
</script>

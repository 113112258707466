<template>
  <v-container>
    <RequiresProfileEvent v-if="requiresProfile" class="mb-4" />

    <PageHeading
      title="Events"
      :actions="actions"
      actions-namespace="events"
      broadcast-actions
    />

    <ActiveEvents class="mt-5" :items="items" />

    <DataTable class="mt-10" v-bind="table" :loading="loading" :items="items" />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RequiresProfileEvent from "~/components/pages/events/RequiresProfileEvent.vue";
import ActiveEvents from "~/components/pages/events/ActiveEvents.vue";
import { PublishableStatus } from "~/layers/admin/models";
import StatusCmp from "~/layers/admin/components/PublishableStatus";
import { FilterOperator } from "~/layers/admin/private/data-table/Filters/types";
import Event from "~/models/dobby/Event";
import {
  EventSimpleDate,
  EventTicketsSold,
  EventAvailableTickets,
} from "#components";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: { RequiresProfileEvent, ActiveEvents },
  setup() {
    definePageMeta({
      rememberNav: "Events",
    });

    const { handle } = useNamespacedEvent("events");
    const toast = useToast();
    const profile = useTransaction<any>("event-profile");

    const { items, loading } = useListData<Event>({
      query: Event.select(["id", "handle", "name", "status"])
        .include("image")
        .include("type", ["id", "name"])
        .include("tickets", ["id"])
        .include("ticketsSold")
        .include("occurrences", ["starts_at", "ends_at"]),
      actions: {
        namespace: "events",
        removeOne: "event:delete",
        removeMany: "events:delete",
      },
    });

    const requiresProfile = computed(() => {
      return !profile.fetching.value && !profile.original.value.exists;
    });

    handle("event:url", (event: Event) => {
      const url = event.$url();
      if (url) {
        navigator.clipboard.writeText(url);
        toast.info("URL copied to clipboard");
      }
    });

    return {
      items,
      loading,
      requiresProfile,
    };
  },
  data: () => ({
    actions: defineActions([
      {
        title: "More options",
        items: [
          {
            title: "Update event profile",
            key: "event:profile",
            to: { name: "settings-profiles-event" },
          },
        ],
      },
      {
        title: "Add event",
        key: "add:event",
        primary: true,
      },
    ]),
    table: defineTable({
      showSelect: true,
      searchable: true,
      applyFilters: true,
      mobile: true,
      avatar: true,
      configurable: true,
      actionsNamespace: "events",

      emptyIcon: "jam:calendar-f",
      emptyTitle: "No events",
      emptyDescription: "Your events will appear here.",

      itemRoute: (item: Event) => ({
        name: "events-handle",
        params: { handle: item.handle },
      }),

      views: [
        { id: "all", title: "All" },
        {
          id: "future",
          title: "Upcoming events",
          emptyTitle: "No upcoming events",
          emptyDescription: "Your upcoming events will appear here.",
          filters: tableFilter({
            startsAt: {
              type: "date",
              operator: FilterOperator.GreaterOrEqual,
              values: [now()],
            },
          }),
        },
        {
          id: "past",
          title: "Past events",
          emptyTitle: "No past events",
          emptyDescription: "Your past events will appear here.",
          filters: tableFilter({
            startsAt: {
              type: "date",
              operator: FilterOperator.Lesser,
              values: [now()],
            },
          }),
        },
      ],

      filters: {
        startsAt: "date",
        // total: "float",
        status: {
          type: "checkbox",
          items: PublishableStatus,
        },
      },

      columns: [
        {
          title: "Date",
          key: "startsAt",
          component: EventSimpleDate,
          width: "60px",
        },
        {
          title: "Event",
          key: "name",
          sortable: true,
          width: "200px",
          type: "title",
        },
        {
          title: "Status",
          key: "status",
          component: StatusCmp,
        },
        { title: "Sold", key: "sold", component: EventTicketsSold },
        {
          title: "Tickets",
          key: "tickets",
          component: EventAvailableTickets,
          display: "desktop",
        },
        { title: "Type", key: "type.name", display: "desktop" },
      ],

      actions: [
        {
          title: "Delete",
          key: "events:delete",
          color: "red",
          icon: "mdi:delete-sweep-outline",
        },
      ],
      itemActions: [
        {
          title: "Copy URL",
          key: "event:url",
          icon: "heroicons:link",
        },
        {
          title: "Delete",
          key: "event:delete",
          color: "red",
          icon: "mdi:delete-sweep-outline",
        },
      ],
    }),
  }),
});
</script>

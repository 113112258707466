<template>
  <div>
    <AppContextualSaveBar />
    <PageHeading :title="title" />
    <LocationInput class="mt-4">
      <template #top>
        <div class="py-2 highlight">
          <div class="pl-4 pr-2 flex-align">
            <div class="heading-2">Location details</div>
            <v-spacer />
            <v-btn
              @click="save"
              :disabled="!canSave"
              :loading="saving"
              color="primary"
              >Save</v-btn
            >
          </div>
          <div class="px-4 caption">
            Orie and your customers will use this information to contact you.
          </div>
        </div>
        <v-divider />
      </template>
    </LocationInput>
    <OpeningHours class="mt-4" />
    <DeactivateLocation class="mt-4" />

    <div class="flex gap-2 mt-4">
      <v-spacer />
      <v-btn
        @click="del"
        :loading="deleting"
        :disabled="!canEdit"
        color="red"
        elevation="1"
        >Delete
      </v-btn>
      <v-btn
        @click="save"
        :loading="saving"
        :disabled="!canSave"
        color="primary"
        elevation="1"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LocationInput from "~/components/pages/settings/locations/LocationInput.vue";
import OpeningHours from "~/components/pages/settings/locations/OpeningHours.vue";
import DeactivateLocation from "~/components/pages/settings/locations/DeactivateLocation.vue";
import { Location } from "~/layers/admin/models";

export default defineComponent({
  components: { LocationInput, OpeningHours, DeactivateLocation },
  setup() {
    definePageMeta({
      layout: "settings",
    });

    const route = useRoute();
    const handle = String(route.params.handle);

    const { original, canSave, canEdit, saving, save } = useSaveContext<any>({
      query: () => {
        const query = Location.query()
          .select("handle", "name", "fulfills_online_orders", "opening_hours")
          .include("address", [
            "id",
            "company",
            "phone",
            "street",
            "street_extra",
            "city",
            "province_code",
            "postal_code",
            "country_code",
          ]);

        return query.findUnique({ handle });
      },
    });

    const title = computed(() => original.value.name || handle);

    const { del, deleting } = useSaveContextDeleteModel({
      model: Location,
      routeOnDelete: { name: "settings-locations" },
    });

    return {
      canSave,
      canEdit,
      saving,
      deleting,
      title,
      del,
      save,
    };
  },
});
</script>

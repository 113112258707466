<template>
  <div>
    <PageHeading
      title="Event profile"
      subtitle="This is the event profile of your business. Helps your customers understand your public events."
      class="mb-4"
      :actions="actions"
      actions-namespace="event-profile"
      no-return
    />
    <ProfileDetails />
    <SupportDetails class="mt-4" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProfileDetails from "~/components/pages/settings/profiles/ProfileDetails.vue";
import SupportDetails from "~/components/pages/settings/profiles/SupportDetails.vue";

export default defineComponent({
  components: {
    ProfileDetails,
    SupportDetails,
  },
  setup() {
    definePageMeta({
      layout: "settings",
    });

    const transaction = useTransaction<any>("event-profile");
    const { handle } = useNamespacedEvent("event-profile");

    provide("transaction", transaction);
    handle("save", transaction.save);

    const actions = computed(() => {
      return defineActions([
        {
          title: "Save",
          key: "save",
          primary: true,
          loading: transaction.saving.value,
          disabled: !transaction.isDirty.value,
        },
      ]);
    });

    return {
      actions,
    };
  },
});
</script>
